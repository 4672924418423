import CardStatsMini from './CardStatsMini';
import CardSettingsLarge from './CardSettingsLarge';
import CardDashboardProfile from './CardDashboardProfile';
import CardFullTable from './CardFullTable';
import CardChartJS from './CardChartJS';

const componentsArray = [
  CardStatsMini,
  CardSettingsLarge,
  CardDashboardProfile,
  CardFullTable,
  CardChartJS
];
export default componentsArray;
