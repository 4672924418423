import CardInfoIcon from './CardInfoIcon.js';
import CardBlogImage from './CardBlogImage.js';
import CardContactUsTitle from './CardContactUsTitle.js';
import CardInfoImage from './CardInfoImage.js';
import CardInfoFullColor from './CardInfoFullColor.js';
import CardBlogFullBg from './CardBlogFullBg.js';
import CardPricing from './CardPricing.js';
import CardBlogFullBgAuthor from './CardBlogFullBgAuthor.js';
import CardContactUsIcons from './CardContactUsIcons.js';

const componentsArray = [
  CardInfoIcon,
  CardBlogImage,
  CardContactUsTitle,
  CardInfoImage,
  CardInfoFullColor,
  CardBlogFullBg,
  CardPricing,
  CardBlogFullBgAuthor,
  CardContactUsIcons
];
export default componentsArray;
