import CardProfileFullPage from './CardProfileFullPage.js';
import CardProductPrice from './CardProductPrice.js';
import CardPricingList from './CardPricingList.js';
import CardTableDetails from './CardTableDetails.js';
import CardInfoIconBg from './CardInfoIconBg.js';
import CardInvoice from './CardInvoice.js';
import CardProductText from './CardProductText.js';
import CardProductPlain from './CardProductPlain.js';
import CardProductsCheckout from './CardProductsCheckout.js';
import CardBilling from './CardBilling.js';
import CardDetailSettingsUserExample1 from './CardDetailSettingsUserExample1.js';
import CardDetailSettingsUserExample2 from './CardDetailSettingsUserExample2.js';
import CardDetailSettingsUserExample3 from './CardDetailSettingsUserExample3.js';
import CardDetailSettingsUserExample4 from './CardDetailSettingsUserExample4.js';
import CardChatPeople from './CardChatPeople.js';
import CardChatDiscussion from './CardChatDiscussion.js';

const componentsArray = [
  CardProfileFullPage,
  CardProductPrice,
  CardPricingList,
  CardTableDetails,
  CardInfoIconBg,
  CardInvoice,
  CardProductText,
  CardProductPlain,
  CardProductsCheckout,
  CardBilling,
  CardDetailSettingsUserExample1,
  CardDetailSettingsUserExample2,
  CardDetailSettingsUserExample3,
  CardDetailSettingsUserExample4,
  CardChatPeople,
  CardChatDiscussion
];
export default componentsArray;
