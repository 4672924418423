/* eslint-disable global-require */
import * as ROUTES from '../constants/routes';

const textProps = {
  color: 'transparent',
  type: 'relative',
  logoText: 'Burgin Collection',
  logoImage: require('../assets/img/brand/burgin_logo.png').default,
  logoLink: { to: '/' },
  rightLinks: [
    {
      icon: 'fas fa-user',
      to: ROUTES.DASHBOARD_PRESENTATION
    },
    {
      dropdown: true,
      text: 'Menu',
      items: [
        {
          to: ROUTES.HOME,
          children: 'Home'
        },
        {
          to: ROUTES.PRESENTATION_BOOKS,

          children: "Daniel Bürgin's Books"
        },
        {
          to: ROUTES.PRESENTATION_JAPANESE,
          children: 'Japanese Collectables'
        },
        {
          to: ROUTES.PRESENTATION_ORIGINAL,
          children: 'Original Art and Crafts'
        },
        {
          to: ROUTES.BESPOKE,
          children: 'Bespoke search'
        },
        {
          to: ROUTES.ABOUT,
          children: 'About'
        },
        {
          to: ROUTES.CONTACT,
          children: 'Contact'
        }
      ]
    },
    {
      dropdown: true,
      text: 'English',
      items: [
        { name: 'English', children: 'English' },
        { name: 'German', children: 'German' }
      ]
    }
  ]
};
export default textProps;
