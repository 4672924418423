const textProps = {
  head: 'Starter License',
  title: '$149',
  description: 'per month',
  list: [
    {
      icon: 'fas fa-book',
      text: 'Complete documentation',
      color: 'lightBlue'
    },
    {
      icon: 'fab fa-sketch',
      text: 'Working materials in Sketch',
      color: 'lightBlue'
    },
    {
      icon: 'fas fa-cloud',
      text: '10,000+ messages in Cloud',
      color: 'lightBlue'
    }
  ],
  link: {
    children: 'Buy Now',
    href: '#pablo'
  },
  footer: {
    children: 'Contact us?',
    href: '#pablo'
  },
  color: 'lightBlue',
  gradient: false
};

export default textProps;
