/* eslint-disable global-require */
const textProps = {
  placeholder: require('../../../assets/img/faces/face-1.jpg').default,
  changeButton: {
    children: 'change',
    size: 'sm',
    color: 'dark'
  },
  removeButton: {
    children: 'remove',
    size: 'sm',
    color: 'red',
    outline: true
  }
};
export default textProps;
