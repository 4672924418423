/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createPopper } from '@popperjs/core';

// components
import {
  changeAvailabilityProduct,
  changeCoverProduct,
  changeShowPriceProduct
} from '../../services/api.js';
import Button from '../Elements/Button.js';

export default function DropdownButton({
  text,
  icon,
  items,
  button,
  page,
  handleControl,
  handleChangeState
}) {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const [menuClasses, setMenuClasses] = React.useState('');
  const [animating, setAntimating] = React.useState(false);
  const [transformOrigin, setTransformOrigin] = React.useState('origin-top-right');
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const [checkedAvailability, setCheckedAvailability] = React.useState(false);
  const [checkedCover, setCheckedCover] = React.useState(false);
  const [checkedPrice, setCheckedPrice] = React.useState(false);
  const [checkedActive, setCheckedActive] = React.useState(false);
  const [checkedProcessing, setCheckedProcessing] = React.useState(false);
  const [checkedShipping, setCheckedShipping] = React.useState(false);
  const [checkedDone, setCheckedDone] = React.useState(false);
  const origins = {
    'top-start': 'origin-bottom-left',
    'top-end': 'origin-bottom-right',
    'bottom-start': 'origin-top-left',
    'bottom-end': 'origin-top-right',
    'right-start': 'origin-top-left',
    'right-end': 'origin-top-left',
    'left-start': 'origin-top-right',
    'left-end': 'origin-top-right',
    bottom: 'origin-top',
    top: 'origin-bottom',
    left: 'origin-right',
    right: 'origin-left'
  };
  const startAnimation = () => {
    if (!animating) {
      setAntimating(true);
      if (dropdownPopoverShow) {
        setMenuClasses('');
        setTimeout(() => {
          setDropdownPopoverShow(false);
        }, 310);
      } else {
        const popperObject = createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
          placement: 'bottom-start'
        });
        setTransformOrigin(origins[popperObject.state.placement]);
        setDropdownPopoverShow(true);
        setTimeout(() => {
          setMenuClasses('opacity-100 scale-100 ');
        }, 10);
      }
      setTimeout(() => {
        setAntimating(false);
      }, 350);
    }
  };

  const handleOnClick = async (event, element, label) => {
    event.preventDefault();
  };

  const toggleAvailability = () => {
    setCheckedAvailability(!checkedAvailability);
  };

  const toggleActive = () => {
    setCheckedActive(!checkedActive);
  };

  const togglePrice = () => {
    setCheckedPrice(!checkedPrice);
  };

  const toggleCover = () => {
    setCheckedCover(!checkedCover);
  };
  const toggleProcessing = () => {
    setCheckedProcessing(!checkedProcessing);
  };

  const toggleShipping = () => {
    setCheckedShipping(!checkedShipping);
  };

  const toggleDone = () => {
    setCheckedDone(!checkedDone);
  };

  const toggleCheck = (event) => {
    console.log('event name checked ', event.target.name);
    if (event.target.name === 'showPrice') {
      togglePrice();
    }
    if (event.target.name === 'setCover') {
      toggleCover();
    }
    if (event.target.name === 'setAvailable') {
      toggleAvailability();
    }
    if (event.target.name === 'setActive') {
      toggleActive();
    }
    if (event.target.name === 'setProcessing') {
      toggleProcessing();
    }
    if (event.target.name === 'setShipping') {
      toggleShipping();
    }
    if (event.target.name === 'setDone') {
      toggleDone();
    }
  };

  const setChecked = (name) => {
    if (name === 'showPrice') {
      return checkedPrice;
    }
    if (name === 'setCover') {
      return checkedCover;
    }
    if (name === 'setAvailable') {
      return checkedAvailability;
    }
    if (name === 'setActive') {
      return checkedActive;
    }
    if (name === 'setProcessing') {
      return checkedProcessing;
    }
    if (name === 'setShipping') {
      return checkedShipping;
    }
    if (name === 'setDone') {
      return checkedDone;
    }
  };
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event) {
      // console.log('event', event.target);
      // console.log(popoverDropdownRef.current);
      if (
        popoverDropdownRef.current &&
        !popoverDropdownRef.current.contains(event.target) &&
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(event.target)
      ) {
        // console.log('Je suis ici');
        setDropdownPopoverShow(false);
      } // else console.log('event', event.target);
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverDropdownRef, btnDropdownRef]);

  useEffect(() => {
    let mounted = true;
    function fillChecked() {
      setCheckedAvailability(page.availability === '1');
      setCheckedCover(page.isCover === '1');
      setCheckedPrice(page.showPrice === '1');
      setCheckedProcessing(page.orderState === '1');
      setCheckedShipping(page.orderState === '2');
      setCheckedDone(page.orderState === '3');
      setCheckedActive(page.active === '1');
    }

    if (mounted) {
      fillChecked();
    }
    return () => (mounted = false);
  }, [page.availability, page.isCover, page.orderState, page.showPrice, page.active]);

  return (
    <>
      <div className="relative">
        <Button
          {...button}
          ref={btnDropdownRef}
          onClick={(e) => {
            e.preventDefault();
            startAnimation();
          }}
        >
          {icon ? <i className={icon + (text ? ' mr-2' : '')} /> : null}
          {text}
          <i className="ml-2 fas fa-caret-down transition-all duration-100 ease-in-out transform " />
        </Button>
        <div
          ref={popoverDropdownRef}
          className={`${dropdownPopoverShow ? 'block ' : 'hidden'} z-50`}
        >
          <div
            className={`${transformOrigin} bg-white text-base float-left p-2 border list-none text-left rounded-lg shadow-lg min-w-max transition-all duration-100 ease-in transform opacity-0 scale-95 absolute ${menuClasses}`}
          >
            {items.map((prop, key) => {
              if (prop.disabled) {
                return (
                  <span
                    className="text-sm px-3 py-2 block w-full whitespace-nowrap bg-transparent text-blueGray-400"
                    key={key}
                  >
                    {prop.children}
                  </span>
                );
              }
              if (prop.to) {
                return (
                  <Link
                    {...prop}
                    key={key}
                    className="text-sm px-3 py-2 block w-full whitespace-nowrap bg-transparent hover:bg-blueGray-100 rounded transition-all duration-100"
                  />
                );
              }
              if (prop.type === 'checkbox') {
                return (
                  <div key={key} className="flex mt-1 flex-row">
                    <div className="h-full flex flex-col align-middle">
                      <input
                        name={prop.name}
                        type="checkbox"
                        checked={setChecked(prop.name)}
                        onChange={(event) => {
                          toggleCheck(event);
                          handleChangeState(
                            event,
                            page,
                            prop.name,
                            checkedPrice,
                            checkedCover,
                            checkedAvailability,
                            checkedActive
                          );
                        }}
                        id={prop.children}
                        className="text-sm my-auto mt-1 w-1 mr-1 bg-transparent hover:bg-blueGray-100 rounded transition-all duration-100"
                      />
                    </div>
                    <label className="text-sm" htmlFor={prop.children}>
                      {prop.children}
                    </label>
                  </div>
                );
              }
              return (
                <button
                  onClick={(event) => handleControl(event, page)}
                  type="button"
                  {...prop}
                  key={key}
                  className="text-sm py-2 block w-full whitespace-nowrap bg-transparent hover:bg-blueGray-100 outline-none focus-within:outline-none focus:outline-none rounded transition-all duration-100"
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

DropdownButton.defaultProps = {
  items: []
};
DropdownButton.propTypes = {
  text: PropTypes.string,
  // if you want an icon to the left of the text
  icon: PropTypes.string,
  // Array of properties to pass to the link object
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  items: PropTypes.arrayOf(PropTypes.object),
  // props that can be passed to the Button component
  button: PropTypes.object,
  handleControl: PropTypes.func,
  handleChangeState: PropTypes.func,
  page: PropTypes.object
};
