/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// components
import Button from '../../Elements/Button.js';
import Input from '../../Elements/Input.js';
import Select from '../../Elements/Select.js';
import InfoAreaIcon from '../../InfoAreas/Presentation/InfoAreaIcon.js';

export default function CardContactUsAddress({
  title,
  button,
  components,
  onChangeControl,
  inputHandler,
  handleSubmit,
  error,
  noshadow,
  user
}) {
  const [checkedNewsLetter, setCheckedNewsLetter] = useState(true);
  const [checkedAddressSame, setCheckedAddressSame] = useState(true);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedNoCreate, setCheckedNoCreate] = useState(false);
  const width = {
    1: 'w-1/12',
    2: 'w-2/12',
    3: 'w-3/12',
    4: 'w-4/12',
    5: 'w-5/12',
    6: 'w-6/12',
    7: 'w-7/12',
    8: 'w-8/12',
    9: 'w-9/12',
    10: 'w-10/12',
    11: 'w-11/12',
    12: 'w-full'
  };
  const sm = {
    1: 'sm:w-1/12',
    2: 'sm:w-2/12',
    3: 'sm:w-3/12',
    4: 'sm:w-4/12',
    5: 'sm:w-5/12',
    6: 'sm:w-6/12',
    7: 'sm:w-7/12',
    8: 'sm:w-8/12',
    9: 'sm:w-9/12',
    10: 'sm:w-10/12',
    11: 'sm:w-11/12',
    12: 'sm:w-full'
  };
  const md = {
    1: 'md:w-1/12',
    2: 'md:w-2/12',
    3: 'md:w-3/12',
    4: 'md:w-4/12',
    5: 'md:w-5/12',
    6: 'md:w-6/12',
    7: 'md:w-7/12',
    8: 'md:w-8/12',
    9: 'md:w-9/12',
    10: 'md:w-10/12',
    11: 'md:w-11/12',
    12: 'md:w-full'
  };
  const lg = {
    1: 'lg:w-1/12',
    2: 'lg:w-2/12',
    3: 'lg:w-3/12',
    4: 'lg:w-4/12',
    5: 'lg:w-5/12',
    6: 'lg:w-6/12',
    7: 'lg:w-7/12',
    8: 'lg:w-8/12',
    9: 'lg:w-9/12',
    10: 'lg:w-10/12',
    11: 'lg:w-11/12',
    12: 'lg:w-full'
  };
  const xl = {
    1: 'xl:w-1/12',
    2: 'xl:w-2/12',
    3: 'xl:w-3/12',
    4: 'xl:w-4/12',
    5: 'xl:w-5/12',
    6: 'xl:w-6/12',
    7: 'xl:w-7/12',
    8: 'xl:w-8/12',
    9: 'xl:w-9/12',
    10: 'xl:w-10/12',
    11: 'xl:w-11/12',
    12: 'xl:w-full'
  };
  const getValue = (name) => {
    // setChecked(!checked);
    if (name === 'firstname') return user ? user.firstname : '';
    if (name === 'lastname') return user ? user.lastname : '';
    if (name === 'email') return user ? user.emailAddress : '';
    if (name === 'phone') return user ? user.phoneNumber : '';
    if (name === 'sexe') return user ? user.sexe : '';
    if (name === 'address') return user ? user.address : '';
    if (name === 'addressLine2') return user ? user.addressLine1 : '';
    if (name === 'addressLine3') return user ? user.addressLine2 : '';
    if (name === 'town') return user ? user.town : '';
    if (name === 'postcode') return user ? user.postcode : '';
    if (name === 'country') return user ? user.country : '';
    if (name === 'deliveryAddress') return user ? user.deliveryAddress : '';
    if (name === 'deliveryAddressLine2') return user ? user.deliveryAddressLine1 : '';
    if (name === 'deliveryAddressLine3') return user ? user.deliveryAddressLine2 : '';
    if (name === 'deliveryTown') return user ? user.deliveryTown : '';
    if (name === 'deliveryPostcode') return user ? user.deliveryPostcode : '';
    if (name === 'addressSame') setCheckedAddressSame(user ? user.isSameAddress === 1 : true);
  };
  useEffect(() => {
    let mounted = true;
    async function checkBox() {
      console.log('user', user);
      setCheckedAddressSame(user ? user.isSameAddress === '1' : true);
    }
    if (mounted) checkBox();
    return () => (mounted = false);
  }, [user]);
  const toggleChecked = (event) => {
    // setChecked(!checked);
    const { name } = event.target;
    if (name === 'addressSame') setCheckedAddressSame(!checkedAddressSame);
    if (name === 'terms') setCheckedTerms(!checkedTerms);
    if (name === 'noCreate') setCheckedNoCreate(!checkedNoCreate);
    if (name === 'newsletter') setCheckedNewsLetter(!checkedNewsLetter);
  };
  const getChecked = (name) => {
    // setChecked(!checked);
    if (name === 'addressSame') return checkedAddressSame;
    if (name === 'terms') return checkedTerms;
    if (name === 'noCreate') return checkedNoCreate;
    if (name === 'newsletter') return checkedNewsLetter;
  };
  return (
    <div className="mb-2">
      <div
        className={`${
          !noshadow ? 'shadow-lg' : ''
        } rounded-lg text-blueGray-500  p-6 mt-0 mb-0 relative flex flex-col min-w-0 break-words bg-white w-full`}
      >
        {title && <h4 className="text-xl font-bold leading-tight pb-6">{title}</h4>}
        <div className="flex-auto">
          <div className="flex flex-wrap -mx-4">
            {components.map((prop, key) =>
              (checkedAddressSame && prop.controlled) ||
              (checkedNoCreate && prop.passwordcreate) ? null : (
                <div
                  className={`px-4 relative ${width[prop.width] || 'w-full'} ${sm[prop.sm] || ''} ${
                    md[prop.md] || ''
                  } ${lg[prop.lg] || ''} ${xl[prop.xl] || ''}`}
                  key={key}
                >
                  {(prop.input || prop.select) && (
                    <div className="mb-3 pt-0 relative">
                      {prop.input && prop.text && (
                        <div className="w-full border border-l-0 border-t-0 border-r-0 mb-2 border-b border-gray-600 px-1 pb-2">
                          <span className="uppercase text-md font-semibold">{prop.text}</span>
                        </div>
                      )}
                      {prop.label && !prop.checkbox && (
                        <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                          {prop.label}
                          <span className="text-red-500">
                            {(prop.input && prop.input.required) ||
                            (prop.select && prop.select.required)
                              ? ' *'
                              : ''}
                          </span>
                        </label>
                      )}
                      {prop.input && prop.input.type === 'checkbox' && (
                        <div className="w-full flex flex-row">
                          <div className=" mr-2 my-auto mb-1">
                            <input
                              checked={getChecked(prop.input.name)}
                              id={prop.input.name}
                              name={prop.input.name}
                              type="checkbox"
                              onChange={(event) => {
                                toggleChecked(event);
                                onChangeControl(event, prop.input, prop.input.name);
                              }}
                              className="w-4 mx-auto ring-0 focus:ring-0 focus-within:ring-0 rounded-md"
                            />
                          </div>
                          <label htmlFor={prop.input.name}>{prop.label}</label>
                        </div>
                      )}
                      {prop.input && prop.input.type !== 'checkbox' && (
                        <Input
                          {...prop.input}
                          onChange={inputHandler}
                          defaultValue={getValue(prop.input.name)}
                        />
                      )}
                      {prop.select && (
                        <Select
                          {...prop.select}
                          onChange={onChangeControl}
                          defaultValue={getValue(prop.select.name)}
                          selected={getValue(prop.select.name)}
                        />
                      )}
                    </div>
                  )}
                  {prop.infoarea && <InfoAreaIcon {...prop.infoarea} />}
                </div>
              )
            )}
          </div>
          <div
            className={`w-full ${
              button.alignleft ? 'text-left' : button.aligncenter ? 'text-center' : 'text-right'
            } mt-2`}
          >
            <div className="text-red-500 text-base pb-4">{error}</div>
            <Button {...button} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

const widthTypes = {
  // The width the component should have by default
  // if this is not set, then it is defaulted to 100% (w-full)
  width: PropTypes.oneOf([1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]),
  // The width the input should have on sm breakpoint
  sm: PropTypes.oneOf([1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]),
  // The width the input should have on md breakpoint
  md: PropTypes.oneOf([1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]),
  // The width the input should have on lg breakpoint
  lg: PropTypes.oneOf([1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]),
  // The width the input should have on xl breakpoint
  xl: PropTypes.oneOf([1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12])
};

const inputsTypes = PropTypes.shape({
  ...widthTypes,
  label: PropTypes.string,
  // It is represetnted by the props you
  // can pass to our Input component element
  // NOTE: if you wish to generate a textarea, you will need to pass
  // // // inside this object >> type: "textarea"
  // // // full example >> input: { type: "textarea" }
  input: PropTypes.object
});

const selectTypes = PropTypes.shape({
  ...widthTypes,
  label: PropTypes.string,
  // It is represetnted by the props you
  // can pass to our Select component element
  select: PropTypes.object
});

const infoAreaTypes = PropTypes.shape({
  // props to pass to the InfoAreaIcon component
  infoarea: PropTypes.object
});

CardContactUsAddress.defaultProps = {
  components: [],
  button: {}
};
CardContactUsAddress.propTypes = {
  user: PropTypes.object,
  error: PropTypes.string,
  title: PropTypes.string,
  noshadow: PropTypes.bool,
  onChangeControl: PropTypes.func,
  inputHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  // props to pass to the Button component
  button: PropTypes.object,
  components: PropTypes.arrayOf(PropTypes.oneOfType([inputsTypes, selectTypes, infoAreaTypes]))
};
