/* eslint-disable global-require */
import React from 'react';

const textProps = {
  image: require('../../../assets/img/ecommerce-2.jpg').default,
  subtitle: 'Trending',
  title: 'Gucci Blazer',
  description:
    'This Gucci blazer has been crafted in Italy from hard-wearing black textured-leather',
  price: '€1,990',
  button: {
    color: 'pink',
    children: <i className="fas fa-heart" />
  }
};
export default textProps;
