const textProps = {
  title: 'Page visits',
  color: 'white',
  head: ['Page name', 'Visitors', 'Unique Users', 'Bounce Rate'],
  body: [
    [
      {
        bold: '/argon/'
      },
      '4,569',
      '340',
      {
        arrow: 'up',
        color: 'emerald',
        text: '46,53%'
      }
    ],
    [
      {
        bold: '/argon/index.html'
      },
      '3,985',
      '319',
      {
        arrow: 'down',
        color: 'orange',
        text: '46,53%'
      }
    ],
    [
      {
        bold: '/argon/charts.html'
      },
      '3,513',
      '294',
      {
        arrow: 'down',
        color: 'orange',
        text: '36,49%'
      }
    ],
    [
      {
        bold: '/argon/tables.html'
      },
      '2,050',
      '147',
      {
        arrow: 'up',
        color: 'emerald',
        text: '50,87%'
      }
    ],
    [
      {
        bold: '/argon/profile.html'
      },
      '1,795',
      '190',
      {
        arrow: 'up',
        color: 'red',
        text: '46,53%'
      }
    ]
  ]
};
export default textProps;
