/* eslint-disable global-require */
const textProps = {
  heading2badge: {
    badge: {
      color: 'lightBlue',
      children: 'Fully Coded'
    },
    title: 'Speed up your development',
    description:
      'Using Notus PRO React you can build Admin Dashboards for your next CMS, ERP, CRM or you can build the presentation pages for your business.'
  },
  imageDevices: require('../../../assets/img/devices.jpg').default,
  infoArea: {
    icon: 'fas fa-rocket',
    iconColor: 'lightBlue',
    title: 'Admin Dashboard & UI Kit',
    description:
      "The extension comes with three pre-built pages to help you get started faster. You can change the text and images and you're good to go.",
    list: [
      {
        icon: 'fas fa-fingerprint',
        text: 'Carefully crafted components'
      },
      { icon: 'fas fa-pager', text: 'Amazing page examples' },
      { icon: 'far fa-paper-plane', text: 'Dynamic components' }
    ]
  }
};
export default textProps;
