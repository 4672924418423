const textProps = {
  badge: {
    color: 'indigo',
    children: 'Comic books'
  },
  title: 'Why you should start reading them',
  description:
    'First, I was afraid, you could actually say I was petrified about them. I kept thinking, how could I do this to myself, how could I make myself read them.',
  alignment: 'center'
};

export default textProps;
