/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/p2.png').default,
  title: 'Notus PRO React',
  description:
    'The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color.',
  button: {
    color: 'red',
    children: 'Get started'
  }
};

export default textProps;
