import mediaplayer from '../mediaplayers/mediaplayerteaminfosocial.js';

const textProps = {
  heading2badge: {
    badge: {
      color: 'emerald',
      children: 'testimonials'
    },
    title: "They've been or not with you",
    description:
      "It's your life, and there are a lot of people with you, but this doesn't mean that everything is ok, it can be anyhow. You stay with your friends and nothing matters anymore.",
    alignment: 'center'
  },
  mediaplayer: { ...mediaplayer }
};
export default textProps;
