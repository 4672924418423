const textProps = {
  headText: 'Standard',
  price: '$25',
  unit: 'per month',
  list: [
    {
      feature: '20GB',
      text: 'File Storage'
    },
    {
      feature: '15',
      text: 'Users/project'
    },
    {
      feature: '4.000',
      text: 'Internal messages'
    }
  ],
  link: {
    href: '#pablo',
    children: 'Request a demo'
  }
};
export default textProps;
