/* eslint-disable global-require */
import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import routes from '../routes/components/index.js';
import DropdownNavbar from './DropdownNavbar.js';
import DropdownHoverNavbar from './DropdownHoverCSSNavbar.js';
import * as ROUTES from '../../constants/routes';
import CartContext from '../../mycomponent/cartContext.js';

export default function NavbarComponents() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const { admin, setAdmin } = useContext(CartContext);
  const [redirect, setRedirect] = useState(false);

  return (
    <>
      <nav className="fixed w-full bg-blueGray-900 flex flex-wrap items-center justify-between px-2 py-3 z-9998">
        <div className="w-full px-4 mx-auto flex flex-wrap items-center justify-between max-h-screen-70 overflow-y-auto">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to={ROUTES.DASHBOARD}
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4  whitespace-nowrap uppercase"
            >
              <div className="flex flex-row py-2">
                <img
                  src={require('../../assets/img/brand/burgin_logo.png').default}
                  alt="..."
                  className=" h-6 mr-2"
                />
                <span className="text-xs">Collection</span>
              </div>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-white fas fa-bars" />
            </button>
          </div>
          <div
            className={`lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none${
              navbarOpen ? ' block rounded lg:shadow-lg' : ' hidden'
            }`}
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto lg:items-center">
              <li>
                <Link
                  to={ROUTES.HOME}
                  className="hover:opacity-75 lg:text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-150 ease-in-out text-blueGray-800"
                >
                  <i className="text-blueGray-500 text-base leading-lg mr-1 lg:text-white fas fa-globe" />
                  <span>Go to website </span>
                </Link>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:items-center">
              <li>
                <button
                  type="button"
                  className="text-blueGray-800 hover:text-blueGray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="#pablo"
                  onClick={(event) => {
                    event.preventDefault();
                    localStorage.removeItem('authAdmin');
                    localStorage.removeItem('authAdminTime');
                    setRedirect(true);
                  }}
                >
                  <i className="text-blueGray-500  lg:text-white fas fa-sign-out-alt text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Star</span>
                </button>
              </li>
              <li>
                <div className="text-blueGray-800 hover:text-blueGray-600 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  <span className="text-blueGray-500  lg:text-white text-sm capitalize ">
                    {admin ? `${admin.firstname} ${admin.lastname}` : ''}
                  </span>
                  <span className="lg:hidden inline-block ml-2">Star</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {redirect ? <Redirect push to={ROUTES.ADMIN_LOGIN} /> : null}
      </nav>
    </>
  );
}
