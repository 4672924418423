import CardBlogImageHorizontal from './CardBlogImageHorizontal';
import CardBlogIllustration from './CardBlogIllustration';
import CardProfileFull from './CardProfileFull';
import CardSocialMedia from './CardSocialMedia';
import CardSocialMediaSecond from './CardSocialMediaSecond';

const componentsArray = [
  CardBlogImageHorizontal,
  CardBlogIllustration,
  CardProfileFull,
  CardSocialMedia,
  CardSocialMediaSecond
];
export default componentsArray;
