import infoCards1 from '../cards/cardinfoicon.js';
import infoArea from '../infoarea/infoareaicontitlelink.js';
import skewCard from '../cards/cardblogimage.js';

const textProps = {
  infoArea: {
    ...infoArea
  },
  skewCard: {
    ...skewCard
  }
};
export default textProps;
