import Button13 from './Button13.js';
import Button14 from './Button14.js';
import Button15 from './Button15.js';
import Button16 from './Button16.js';
import Button17 from './Button17.js';
import Button18 from './Button18.js';
import Button19 from './Button19.js';
import Button20 from './Button20.js';
import Button21 from './Button21.js';
import Button22 from './Button22.js';
import Button23 from './Button23.js';
import Button24 from './Button24.js';

const componentsArray = [
  Button13,
  Button14,
  Button15,
  Button16,
  Button17,
  Button18,
  Button19,
  Button20,
  Button21,
  Button22,
  Button23,
  Button24
];
export default componentsArray;
