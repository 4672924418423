import admin from './_admin.js';
import auth from './_auth.js';
import blog from './_blog.js';
import eCommerce from './_e-commerce.js';
import error from './_error.js';
import misc from './_misc.js';
import presentation from './_presentation.js';

const routesArray = [
  { ...presentation },
  { ...admin },
  { ...eCommerce },
  { ...blog },
  { ...auth },
  { ...misc },
  { ...error }
];

export default routesArray;
