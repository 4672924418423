const textProps = {
  color: 'emerald',
  subtitle: 'Marvel vs DC comics',
  title: 'An old question from the ages',
  description:
    "I don't know which question is harder 'Marvel or DC comics, which one is better?', or 'Which one was first, the egg, or the chicken?'. Maybe we'll never find out!",
  alignment: 'center'
};

export default textProps;
