/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/team-2-800x800.jpg').default,
  name: 'Jenna Stones',
  location: 'New York, USA',
  description:
    'An artist of considerable range, Jenna the name taken by Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and records all of his own music, giving it a warm.',
  showMore: {
    children: 'Show more',
    href: '#pablo'
  },
  stats: [
    {
      title: '138',
      subtitle: 'Projects'
    },
    {
      title: '3,660',
      subtitle: 'Tasks'
    },
    {
      title: '35+',
      subtitle: 'Team Size'
    }
  ],
  achievements: [
    {
      icon: 'fas fa-briefcase',
      text: 'Solution Manager - Creative Tim Officer'
    },
    {
      icon: 'fas fa-university',
      text: 'University of Computer Science'
    }
  ]
};
export default textProps;
