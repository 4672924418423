import CardInfoIconLeft from './CardInfoIconLeft.js';
import CardInfoWithButton from './CardInfoWithButton.js';
import CardBlogandAuthor from './CardBlogandAuthor.js';
import CardProfileSkills from './CardProfileSkills.js';
import CardProfileFullDetails from './CardProfileFullDetails.js';
import CardProfileActions from './CardProfileActions.js';
import CardProfileReviews from './CardProfileReviews.js';
import CardProjectTeam from './CardProjectTeam.js';
import CardProjectImageFullBg from './CardProjectImageFullBg.js';
import CardPricingDetails from './CardPricingDetails.js';
import CardPricingList from './CardPricingList.js';
import CardPricingLogo from './CardPricingLogo.js';
import CardContactUsAddress from './CardContactUsAddress.js';
import CardContactUsHorizontal from './CardContactUsHorizontal.js';

const componentsArray = [
  CardInfoIconLeft,
  CardInfoWithButton,
  CardBlogandAuthor,
  CardProfileSkills,
  CardProfileFullDetails,
  CardProfileActions,
  CardProfileReviews,
  CardProjectTeam,
  CardProjectImageFullBg,
  CardPricingDetails,
  CardPricingList,
  CardPricingLogo,
  CardContactUsAddress,
  CardContactUsHorizontal
];
export default componentsArray;
