import React from 'react';
import { useLocation } from 'react-router-dom';

// sections
import Error1 from '../../components/Sections/Error/Error1.js';
// components
import NavbarLinks from '../../components/Navbars/NavbarLinks.js';
// texts for sections as props
import error1error404 from '../../_texts/error/error1error404.js';
// texts for components as props
import navbarlinkslanding2 from '../../_texts/presentation/navbars/navbarlinkslanding2.js';
import navbarlinks from '../../content/navbarlinks';

export default function Error404() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <NavbarLinks {...navbarlinks} />
      <Error1 {...error1error404} />
    </>
  );
}
