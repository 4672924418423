const textProps = {
  statSubtitle: 'Traffic',
  statTitle: '350,897',
  statArrow: 'up',
  statPercent: '3.48',
  statPercentColor: 'text-emerald-500',
  statDescripiron: 'Since last month',
  statIconName: 'far fa-chart-bar',
  statIconColor: 'bg-red-500'
};
export default textProps;
