import cardprofilefulldetails from '../cards/cardprofilefulldetails.js';

const textProps = {
  heading2badge: {
    badge: { color: 'pink', children: 'You need trust' },
    title: 'North, south, east, west',
    description:
      "I do not trust anyone, it is better this way. You shouldn't trust anyone that does not trust you. Believe me, when I say, you can always who is false and who is true.",
    alignment: 'center'
  },
  card: { ...cardprofilefulldetails }
};

export default textProps;
