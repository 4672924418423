import mediaplayerteaminfo from '../mediaplayers/mediaplayerteaminfo.js';

const textProps = {
  heading3badge: {
    badge: { color: 'dark', children: 'See our customers opinion' },
    title: 'What Clients Say',
    description:
      'Meet Wingman, a robust suite of styled pages and components, powered by Bootstrap 4. The ideal starting point for product landing pages, stylish web-apps and complete company websites.',
    alignment: 'left'
  },
  mediaplayer: {
    ...mediaplayerteaminfo
  }
};
export default textProps;
