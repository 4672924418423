const textProps = {
  icon: 'fas fa-cog',
  button: {
    color: 'white'
  },
  items: [
    {
      href: '#pablo',
      children: 'Edit Profile'
    },
    {
      href: '#pablo',
      children: 'Settings'
    },
    {
      href: '#pablo',
      children: 'Log out'
    }
  ]
};
export default textProps;
