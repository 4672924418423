/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/team-1-800x800.jpg').default,
  items: [
    {
      href: '#pablo',
      children: 'Action'
    },
    {
      href: '#pablo',
      children: 'Another action'
    },
    {
      href: '#pablo',
      children: 'Something else here'
    },
    {
      divider: true
    },
    {
      href: '#pablo',
      children: 'Seprated link'
    }
  ]
};
export default textProps;
