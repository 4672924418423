/* eslint-disable no-plusplus */
import { API_URL } from '../constants/url';

function twoDigits(d) {
  if (d >= 0 && d < 10) return `0${d.toString()}`;
  if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
  return d.toString();
}

// eslint-disable-next-line no-extend-native
Date.prototype.toMysqlFormat = function () {
  return `${this.getUTCFullYear()}-${twoDigits(1 + this.getUTCMonth())}-${twoDigits(
    this.getUTCDate()
  )} ${twoDigits(this.getUTCHours())}:${twoDigits(this.getUTCMinutes())}:${twoDigits(
    this.getUTCSeconds()
  )}`;
};

export async function getJournals(idUser, _page) {
  const url = `${API_URL}getJournals.php`;
  console.log('req', JSON.stringify({ userId: idUser, page: _page }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser, page: _page })
  }).then((data) => data.json());
}
export async function registerPage(
  title,
  descriptionEnglish,
  descriptionGerman,
  heading,
  headingGerman,
  titleGerman
) {
  const url = `${API_URL}registerPage.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('title', title.trim());
  form.append('descriptionEnglish', descriptionEnglish);
  form.append('descriptionGerman', descriptionGerman);
  form.append('heading', heading);
  form.append('headingGerman', headingGerman);
  form.append('titleGerman', titleGerman);
  form.append('active', 0);
  // eslint-disable-next-line no-restricted-syntax
  /*   for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerCategory(title, heading, headingGerman, titleGerman) {
  const url = `${API_URL}registerCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('title', title.trim());
  form.append('heading', heading);
  form.append('headingGerman', headingGerman);
  form.append('titleGerman', titleGerman);
  form.append('active', 0);
  // eslint-disable-next-line no-restricted-syntax
  /*   for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerProduct(product) {
  const url = `${API_URL}registerProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('productName', product._productName);
  form.append('descriptionProduct', product._descriptionProduct);
  form.append('title', product._title);
  form.append('yearPublished', product._yearPublished);
  form.append('publishingNotes', product._publishingNotes);
  form.append('languagePublication', product._languagePublication);
  form.append('ISBN', product._ISBN);
  form.append('author', product._author);
  form.append('subCategory', product._subCategory);
  form.append('category', product._category);
  form.append('availability', product._availability);
  form.append('picture', product._picture);
  form.append('isCover', product._isCover);
  form.append('price', product._price);
  form.append('showPrice', product._showPrice);
  form.append('typeMedia', product._typeMedia);
  form.append('file', product._mediaObject);
  form.append('descriptionProductGerman', product._descriptionProductGerman);
  form.append('publishingNotesGerman', product._publishingNotesGerman);
  form.append('priceEuro', product._priceEuro);
  form.append('priceSwiss', product._priceSwiss);
  form.append('priceJapan', product._priceJapan);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function deleteProduct(productId) {
  const url = `${API_URL}deleteProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('productId', productId);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updatePage(
  title,
  descriptionEnglish,
  descriptionGerman,
  heading,
  headingGerman,
  titleGerman
) {
  const url = `${API_URL}updatePage.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('title', title.trim().toLowerCase());
  form.append('descriptionEnglish', descriptionEnglish);
  form.append('descriptionGerman', descriptionGerman);
  form.append('heading', heading);
  form.append('headingGerman', headingGerman);
  form.append('titleGerman', titleGerman);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updatePageActive(
  title,
  descriptionEnglish,
  descriptionGerman,
  heading,
  headingGerman,
  titleGerman,
  active
) {
  const url = `${API_URL}updatePageActive.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('title', title.trim().toLowerCase());
  form.append('descriptionEnglish', descriptionEnglish);
  form.append('descriptionGerman', descriptionGerman);
  form.append('heading', heading);
  form.append('headingGerman', headingGerman);
  form.append('titleGerman', titleGerman);
  form.append('active', active);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getPages() {
  const url = `${API_URL}getPages.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  }).then((data) => data.json());
}

export async function getPagesFrontend() {
  const url = `${API_URL}getPagesFrontend.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  }).then((data) => data.json());
}

export async function getBooks() {
  const url = `${API_URL}getBooks.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  }).then((data) => data.json());
}

export async function getVarious() {
  const url = `${API_URL}getVarious.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  }).then((data) => data.json());
}

export async function getOriginals() {
  const url = `${API_URL}getOriginals.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  }).then((data) => data.json());
}

export async function getJapanese() {
  const url = `${API_URL}getJapanese.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  }).then((data) => data.json());
}

export async function getItemsByCategory(category) {
  const url = `${API_URL}getItemsByCategory.php`;
  const form = new FormData();
  form.append('category', category);
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((data) => data.json());
}
export async function changeCategoryActive(category, active) {
  const url = `${API_URL}changeCategoryActive.php`;
  const form = new FormData();
  form.append('category', category);
  form.append('active', active);
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((data) => data.json());
}

export async function getCategoryActive(category) {
  const url = `${API_URL}getCategoryActive.php`;
  const form = new FormData();
  form.append('category', category);
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((data) => data.json());
}

export async function updateProduct(product) {
  const url = `${API_URL}updateProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('id', product._id);
  form.append('productName', product._productName);
  form.append('descriptionProduct', product._descriptionProduct);
  form.append('title', product._title);
  form.append('yearPublished', product._yearPublished);
  form.append('publishingNotes', product._publishingNotes);
  form.append('languagePublication', product._languagePublication);
  form.append('ISBN', product._ISBN);
  form.append('author', product._author);
  form.append('subCategory', product._subCategory);
  form.append('category', product._category);
  form.append('availability', product._availability);
  form.append('picture', product._picture);
  form.append('isCover', product._isCover);
  form.append('price', product._price);
  form.append('showPrice', product._showPrice);
  form.append('typeMedia', product._typeMedia);
  form.append('file', product._mediaObject);
  form.append('descriptionProductGerman', product._descriptionProductGerman);
  form.append('publishingNotesGerman', product._publishingNotesGerman);
  form.append('priceEuro', product._priceEuro);
  form.append('priceSwiss', product._priceSwiss);
  form.append('priceJapan', product._priceJapan);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateAvailability(idProduct, availability) {
  const url = `${API_URL}updateAvailability.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idProduct', idProduct);
  form.append('availability', availability);
  // eslint-disable-next-line no-restricted-syntax
  /*   for (const pair of form.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`);
      } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateShowPrice(idProduct, showPrice) {
  const url = `${API_URL}updateShowPrice.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idProduct', idProduct);
  form.append('showPrice', showPrice);
  // eslint-disable-next-line no-restricted-syntax
  /*   for (const pair of form.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`);
        } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerSubcategory(subcategory, category) {
  const url = `${API_URL}registerSubcategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategoryName', subcategory);
  form.append('category', category);
  // eslint-disable-next-line no-restricted-syntax
  /*   for (const pair of form.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
          } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getSubcategoriesByCategory(category) {
  const url = `${API_URL}getSubcategoriesByCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('category', category);
  // eslint-disable-next-line no-restricted-syntax
  /*   for (const pair of form.entries()) {
              console.log(`${pair[0]}, ${pair[1]}`);
            } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function getSubcategories() {
  const url = `${API_URL}getSubcategories.php`;
  return fetch(url, {
    method: 'POST'
  }).then((res) => res.json());
}

export async function getSubcategoriesFront() {
  const url = `${API_URL}getSubcategoriesFront.php`;
  return fetch(url, {
    method: 'POST'
  }).then((res) => res.json());
}

export async function changeAvailabilityProduct(idProduct, availability) {
  const url = `${API_URL}changeAvailabilityProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idProduct', idProduct);
  form.append('availability', availability);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function changeShowPriceProduct(idProduct, showPrice) {
  const url = `${API_URL}changeShowPriceProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idProduct', idProduct);
  form.append('showPrice', showPrice);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function changeCoverProduct(idProduct, isCover, subCategory) {
  const url = `${API_URL}changeCoverProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idProduct', idProduct);
  form.append('isCover', isCover);
  form.append('subcategory', subCategory);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getPageByTitle(title) {
  const url = `${API_URL}getPageByTitle.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('titlePage', title);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getBooksPresentation() {
  const url = `${API_URL}getBooksPresentation.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('titlePage', title);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function getSubCategoryByCategoryPresentation(category, criteria) {
  const url = `${API_URL}getSubCategoryByCategoryPresentation.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('category', category);
  form.append('criteria', criteria);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getBooksBySubCategory(subcategory, criteria) {
  const url = `${API_URL}getBooksBySubCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategory', subcategory);
  form.append('criteria', criteria);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getOriginalsBySubCategory(subcategory, criteria) {
  const url = `${API_URL}getOriginalsBySubCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategory', subcategory);
  form.append('criteria', criteria);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getJapaneseBySubCategory(subcategory, criteria) {
  const url = `${API_URL}getJapaneseBySubCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategory', subcategory);
  form.append('criteria', criteria);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getNewBySubCategory(subcategory, criteria) {
  const url = `${API_URL}getNewBySubCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategory', subcategory);
  form.append('criteria', criteria);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getCategoryByName(subcategory) {
  const url = `${API_URL}getCategoryByName.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategory', subcategory);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getVariousBySubCategory(subcategory, criteria) {
  const url = `${API_URL}getVariousBySubCategory.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('subcategory', subcategory);
  form.append('criteria', criteria);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getProductById(idProduct) {
  const url = `${API_URL}getProductById.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idProduct', idProduct);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerImagesProduct(idProduct, nameProduct, files) {
  const url = `${API_URL}saveImageProduct.php`;
  const form = new FormData();

  for (let i = 0; i < files.length; i++) {
    form.append(`images[${i}]`, files[i]);
  }
  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('idProduct', idProduct);
  form.append('productName', nameProduct);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerImagesPage(titlePage, files) {
  const url = `${API_URL}saveImagePage.php`;
  const form = new FormData();

  for (let i = 0; i < files.length; i++) {
    form.append(`images[${i}]`, files[i]);
  }
  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('titlePage', titlePage);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function removeMediaPage(idMedia, name, titlePage) {
  const url = `${API_URL}removeMediaPage.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('idMedia', idMedia);
  form.append('name', name);
  form.append('titlePage', titlePage);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function removeMediaProduct(idMedia, name, productId) {
  const url = `${API_URL}removeMediaProduct.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('idMedia', idMedia);
  form.append('name', name);
  form.append('productId', productId);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerUser(user) {
  const url = `${API_URL}registerUser.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('firstname', user._firstname);
  form.append('lastname', user._lastname);
  form.append('phoneNumber', user._phoneNumber);
  form.append('emailAddress', user._emailAddress);
  form.append('password', user._password);
  form.append('address', user._address);
  form.append('addressLine1', user._addressLine1);
  form.append('addressLine2', user._addressLine2);
  form.append('country', user._country);
  form.append('idCart', user._idCart);
  form.append('town', user._town);
  form.append('postcode', user._postcode);
  form.append('deliveryAddress', user._deliveryAddress);
  form.append('deliveryAddressLine1', user._deliveryAddressLine1);
  form.append('deliveryAddressLine2', user._deliveryAddressLine2);
  form.append('deliveryTown', user._deliveryTown);
  form.append('deliveryPostCode', user._deliveryPostCode);
  form.append('isSameAddress', user._sameAddress);
  form.append('isRegistering', user._registration);
  form.append('newsletter', user._newsletter);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateUser(user) {
  const url = `${API_URL}updateUser.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('id', user._id);
  form.append('firstname', user._firstname);
  form.append('lastname', user._lastname);
  form.append('phoneNumber', user._phoneNumber);
  form.append('emailAddress', user._emailAddress);
  form.append('password', user._password);
  form.append('address', user._address);
  form.append('addressLine1', user._addressLine1);
  form.append('addressLine2', user._addressLine2);
  form.append('country', user._country);
  form.append('idCart', user._idCart);
  form.append('town', user._town);
  form.append('postcode', user._postcode);
  form.append('deliveryAddress', user._deliveryAddress);
  form.append('deliveryAddressLine1', user._deliveryAddressLine1);
  form.append('deliveryAddressLine2', user._deliveryAddressLine2);
  form.append('deliveryTown', user._deliveryTown);
  form.append('deliveryPostCode', user._deliveryPostCode);
  form.append('isSameAddress', user._sameAddress);
  form.append('isRegistering', user._registration);
  form.append('newsletter', user._newsletter);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateUserAddress(user) {
  const url = `${API_URL}updateUserAddress.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('id', user._id);
  form.append('address', user._address);
  form.append('addressLine1', user._addressLine1);
  form.append('addressLine2', user._addressLine2);
  form.append('country', user._country);
  form.append('town', user._town);
  form.append('postcode', user._postcode);
  form.append('deliveryAddress', user._deliveryAddress);
  form.append('deliveryAddressLine1', user._deliveryAddressLine1);
  form.append('deliveryAddressLine2', user._deliveryAddressLine2);
  form.append('deliveryTown', user._deliveryTown);
  form.append('deliveryPostCode', user._deliveryPostCode);
  form.append('isSameAddress', user._sameAddress);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateUserInfo(user) {
  const url = `${API_URL}updateUserInfo.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('id', user._id);
  form.append('firstname', user._firstname);
  form.append('lastname', user._lastname);
  form.append('phoneNumber', user._phoneNumber);
  form.append('emailAddress', user._emailAddress);
  form.append('newsletter', user._newsletter);
  form.append('sexe', user._sexe);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function changePassword(user) {
  const url = `${API_URL}changePassword.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('id', user._id);
  form.append('oldPassword', user._oldPassword);
  form.append('newPassword', user._newPassword);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getUserOrders(userId) {
  const url = `${API_URL}getUserOrders.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('id', userId);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getOrders() {
  const url = `${API_URL}getOrders.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('id', null);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function getMessages() {
  const url = `${API_URL}getMessages.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('id', null);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getBespokes() {
  const url = `${API_URL}getBespokes.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('id', null);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function changeStateMessage(idMessage) {
  const url = `${API_URL}changeStateMessage.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('id', idMessage);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function changeStateBespoke(idMessage) {
  const url = `${API_URL}changeStateBespoke.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('id', idMessage);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function changeStateOrder(idUser, idSession, state) {
  const url = `${API_URL}changeStateOrder.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idUser', idUser);
  form.append('idSession', idSession);
  form.append('state', state);
  form.append('date', new Date().toMysqlFormat());

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerCartSession(idSession, idUser, productId, qty, price) {
  const url = `${API_URL}registerCartSession.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('sessionId', idSession);
  form.append('userId', idUser);
  form.append('productId', productId);
  form.append('quantity', qty);
  form.append('price', price);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function saveOrder(idSession, idUser, devise) {
  const url = `${API_URL}saveOrder.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('sessionId', idSession);
  form.append('userId', idUser);
  form.append('orderDate', new Date().toMysqlFormat());
  form.append('orderDateCart', new Date().toMysqlFormat());
  form.append('orderState', 1);
  form.append('devise', devise);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function sendMessage(message) {
  const url = `${API_URL}sendMessage.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('title', message._title);
  form.append('firstname', message._firstname);
  form.append('lastname', message._lastname);
  form.append('email', message._email);
  form.append('phoneNumber', message._phoneNumber);
  form.append('reason', message._reason);
  form.append('message', message._message);
  form.append('date', new Date().toMysqlFormat());
  form.append('newsletter', message._newsletter);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function sendEmailOrder(idUser, idOrder, language) {
  const url = `${API_URL}sendEmailOrder.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('idUser', idUser);
  form.append('idOrder', idOrder);
  form.append('language', language);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function sendEmailOrderAdmin(idUser, idOrder) {
  const url = `${API_URL}sendEmailOrderAdmin.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('idUser', idUser);
  form.append('idOrder', idOrder);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function sendBespoke(message) {
  const url = `${API_URL}sendBespoke.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('title', message._title);
  form.append('firstname', message._firstname);
  form.append('lastname', message._lastname);
  form.append('email', message._email);
  form.append('phoneNumber', message._phoneNumber);
  form.append('reason', message._reason);
  form.append('bespoke', message._message);
  form.append('date', new Date().toMysqlFormat());
  form.append('newsletter', message._newsletter);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function validateEmail(email, code) {
  const url = `${API_URL}verifyCodeUser.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('email', email);
  form.append('code', code);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function authenticateUser(email, password) {
  const url = `${API_URL}authenticateUser.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('email', email);
  form.append('password', password);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function authenticateAdmin(email, password) {
  const url = `${API_URL}authenticateAdmin.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('email', email);
  form.append('password', password);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function resendCode(email, language) {
  const url = `${API_URL}resendCode.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  // form.append('files', files);
  form.append('email', email);
  form.append('language', language);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
