/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/faces/team-3.jpg').default,
  input: {
    type: 'textarea',
    rows: 1,
    placeholder: 'Write your comment'
  }
};
export default textProps;
