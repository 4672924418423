import prefooter from '../prefooters/prefootersmall.js';

const textProps = {
  heading3: {
    color: 'light',
    subtitle: "It's 2021",
    title: 'One, two and three of zeros',
    description:
      "People are talking, they're talking a lot, but I hit the volume of my headphones. I've got a clear road ahead in my mind, I go through you, all those masked people.",
    alignment: 'center',
    whiteContent: true
  },
  prefooter: { ...prefooter }
};
export default textProps;
