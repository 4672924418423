import infoArea from '../infoarea/infoareaicontitlelistcolor.js';

const textProps = {
  heading2badge: {
    badge: {
      color: 'lightBlue',
      children: 'with burned soles'
    },
    title: 'You need to fight like David with Goliath',
    description:
      'Brother, life in est is not like in the west. Over the night, the black clouds appear, but at least I have my poetry for my freedom. The victory is for those that rise after they fall.'
  },
  image:
    'https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
  infoArea: {
    ...infoArea
  }
};
export default textProps;
