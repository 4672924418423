/* eslint-disable global-require */
const textProps = {
  username: 'Michael Lewis',
  description:
    'You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed.',
  image: require('../../../assets/img/faces/team-1.jpg').default,
  likes: {
    children: 3,
    href: '#pablo'
  },
  shares: {
    children: 2,
    href: '#pablo'
  }
};
export default textProps;
