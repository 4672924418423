const textProps = {
  title: "Let's keep in touch!",
  description: 'Find us on any of these platforms, we respond 1-2 business days.',
  copyright: `Copyright © ${new Date().getFullYear()} Notus PRO React by Creative Tim.`,
  socials: [
    {
      icon: 'twitter',
      link: 'https://twitter.com/CreativeTim/'
    },
    {
      icon: 'facebook',
      link: 'https://www.facebook.com/CreativeTim/'
    },
    {
      icon: 'dribbble',
      link: 'https://dribbble.com/creativetim'
    },
    {
      icon: 'github',
      link: 'https://github.com/creativetimofficial'
    }
  ],
  links: [
    {
      name: 'Useful Links',
      items: [
        {
          href: 'https://www.creative-tim.com/presentation?npr-landing-1',
          target: '_blank',
          children: 'About Us'
        },
        {
          href: 'https://www.creative-tim.com/blog?npr-landing-1',
          target: '_blank',
          children: 'Blog'
        },
        {
          href: 'https://github.com/creativetimofficial',
          target: '_blank',
          children: 'Github'
        },
        {
          href: 'https://www.creative-tim.com/templates/free?npr-landing-1',
          target: '_blank',
          children: 'Free Products'
        }
      ]
    },
    {
      name: 'Other Resources',
      items: [
        {
          href: 'https://www.creative-tim.com/license?npr-landing-1',
          target: '_blank',
          children: 'License'
        },
        {
          href: 'https://www.creative-tim.com/terms?npr-landing-1',
          target: '_blank',
          children: 'Terms & Conditions'
        },
        {
          href: 'https://www.creative-tim.com/privacy?npr-landing-1',
          target: '_blank',
          children: 'Privacy Policy'
        },
        {
          href: 'https://www.creative-tim.com/contact-us?npr-landing-1',
          target: '_blank',
          children: 'Contact Us'
        }
      ]
    }
  ]
};
export default textProps;
