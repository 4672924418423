/* eslint-disable global-require */
const textProps = {
  title: 'Miami Vice',
  description:
    'The simplest visual description uses ordinary words to convey what the writer sees. First he or she must look at the subject – slowly, carefully, and repeatedly, if possible – to identify the parts that make the whole',
  bgImage: require('../../../assets/img/sections/unsplashs.jpg').default,
  userimage: require('../../../assets/img/faces/team-1.jpg').default,
  username: 'by Martin Jay',
  user: {
    href: '#pablo'
  },
  button: {
    color: 'red',
    children: 'Read more...'
  }
};
export default textProps;
