const textProps = {
  icon: 'fas fa-rocket',
  iconColor: 'lightBlue',
  title: 'A growing company',
  description:
    "The extension comes with three pre-built pages to help you get started faster. You can change the text and images and you're good to go.",
  list: [
    {
      icon: 'fas fa-fingerprint',
      text: 'Carefully crafted components'
    },
    { icon: 'fas fa-pager', text: 'Amazing page examples' },
    { icon: 'far fa-paper-plane', text: 'Dynamic components' }
  ]
};
export default textProps;
