import Navbar from './Navbar.js';
import NavbarBrand from './NavbarBrand.js';
import NavbarButton from './NavbarButton.js';
import NavbarCollapse from './NavbarCollapse.js';
import NavbarContainer from './NavbarContainer.js';
import NavbarList from './NavbarList.js';
import NavbarListItem from './NavbarListItem.js';
import NavbarListLink from './NavbarListLink.js';
import NavbarExample from './NavbarExample.js';

const componentsArray = [
  Navbar,
  NavbarBrand,
  NavbarButton,
  NavbarCollapse,
  NavbarContainer,
  NavbarList,
  NavbarListItem,
  NavbarListLink,
  NavbarExample
];
export default componentsArray;
