/* eslint-disable global-require */
const textProps = {
  items: [
    {
      title: 'Lili Watson',
      description:
        'Artist is a term applied to a person who engages in an activity deemed to be an art. An artist also may be defined unofficially as "a person should is one who expresses him- or herself through a medium".',
      image: require('../../../assets/img/theme/kareya-saleh.jpg').default,
      socials: [
        { icon: 'twitter', link: '#pablo' },
        { icon: 'facebook', link: '#pablo' },
        { icon: 'dribbble', link: '#pablo' }
      ],
      list: [
        {
          icon: 'fas fa-atom',
          text: 'Dedicated entrepreneur',
          color: 'pink'
        },
        {
          icon: 'fas fa-running',
          text: 'Urban city exploration',
          color: 'emerald'
        },
        {
          icon: 'fab fa-facebook',
          text: 'Active on Facebook since 2015',
          color: 'lightBlue'
        }
      ]
    },
    {
      title: 'Lucy Marvel',
      description:
        'A special person may be defined unofficially as "a person should is one who expresses him- or herself through a medium". He is should a descriptive term applied to a person who engages in an activity deemed to be an art.',
      image: require('../../../assets/img/theme/lucy.jpg').default,
      socials: [
        { icon: 'twitter', link: '#pablo' },
        { icon: 'facebook', link: '#pablo' },
        { icon: 'dribbble', link: '#pablo' }
      ],
      list: [
        {
          icon: 'fas fa-atom',
          text: 'Great CEO of Software Company',
          color: 'red'
        },
        {
          icon: 'fas fa-running',
          text: 'Won 12 sprints',
          color: 'emerald'
        },
        {
          icon: 'fab fa-dribbble',
          text: 'On Dribbble since 2018',
          color: 'pink'
        }
      ]
    }
  ]
};
export default textProps;
