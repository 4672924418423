/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/city-1.jpg').default,
  title: 'Portofino one of the best for remote working',
  description:
    'Finding temporary housing should be as easy as renting an Airbnb. That’s the idea behind Portofino, which raised $65 million to expand its pet sitting businesses. This come as the right move for the investment while the planet is moving on work from home...',
  link: {
    href: '#pablo',
    children: 'Read More'
  },
  user: require('../../../assets/img/faces/face-1.jpg').default,
  username: 'Laura Hanks',
  userlink: {
    href: '#pablo'
  },
  date: 'Published 4 days ago'
};
export default textProps;
