import Button1 from './Button1.js';
import Button2 from './Button2.js';
import Button3 from './Button3.js';
import Button4 from './Button4.js';
import Button5 from './Button5.js';
import Button6 from './Button6.js';
import Button7 from './Button7.js';
import Button8 from './Button8.js';
import Button9 from './Button9.js';
import Button10 from './Button10.js';
import Button11 from './Button11.js';
import Button12 from './Button12.js';

const componentsArray = [
  Button1,
  Button2,
  Button3,
  Button4,
  Button5,
  Button6,
  Button7,
  Button8,
  Button9,
  Button10,
  Button11,
  Button12
];
export default componentsArray;
