const textProps = {
  heading2: {
    color: 'light',
    subtitle: "Let's keep in contact",
    title: 'Subscribe to our Newsletter',
    description:
      'Join our newsletter and get news in your inbox every week! We hate spam too, so no worries about this.',
    alignment: 'center',
    whiteContent: true
  },
  input: {
    leftIcon: 'fa fa-envelope',
    placeholder: 'Email',
    border: 'border'
  },
  button: {
    color: 'pink',
    children: 'Subscribe',
    fullWidth: true
  }
};
export default textProps;
