import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import {
  registerImagesPage,
  registerImagesProduct,
  registerSubcategory,
  removeMediaPage,
  removeMediaProduct
} from '../../services/api';

export default function DialogImagePage({ titlePage, setOpenDialog, pageMedias, setReload }) {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);

  const cancelButtonRef = useRef(null);

  const handleSaveImages = async (event) => {
    event.preventDefault();
    setError('');
    if (files.length > 0) {
      const res = await registerImagesPage(titlePage, files);
      console.log(res);
      if (res.message) {
        setOpen(true);
        setOpenDialog(false);
        setReload(true);
      } else {
        setError(res.error);
      }
    } else {
      setError('No file choseen');
    }
  };
  const handleRemoveImage = async (event, imageId, imageIndex, name) => {
    event.preventDefault();
    const res = await removeMediaPage(imageId, name, titlePage);
    if (res.result) {
      console.log(res);
      setReload(true);
      pageMedias.splice(imageIndex, 1);
    } else if (res.error) {
      // console.log(res);
    }
  };
  const handleOnchange = async (event) => {
    event.preventDefault();
    setError('');
    setFiles(event.target.files);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto md:w-3/4 mx-auto  "
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mt-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="w-8/12 mx-auto shadow-xl border rounded border-trueGray-900"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 pb-2 pt-2 font-medium text-gray-900"
                    >
                      Item images
                    </Dialog.Title>
                    {error.length > 0 && <p className="text-red-500 pt-2 pb-2">{error}</p>}
                    <div className="flex flex-row w-full overflow-x-auto">
                      {pageMedias && pageMedias.length > 0
                        ? pageMedias.map((media, key) => (
                            <div className="flex flex-col" key={key}>
                              <a
                                key={key}
                                href={`images/pages/medias/${titlePage}/${media.name}`}
                                target="_blank"
                                className="mr-2 h-24 w-24 border border-gray-600 rounded-xl"
                                rel="noreferrer"
                              >
                                <img
                                  src={`images/pages/medias/${titlePage}/${media.name}`}
                                  alt="item"
                                  className="h-24 rounded-xl"
                                />
                              </a>
                              <button
                                type="button"
                                className="py-2 text-red-500 text-sm font-semibold outline-none focus-within:outline-none focus:outline-none"
                                onClick={(event) => {
                                  handleRemoveImage(event, media.id, key, media.name);
                                }}
                              >
                                <i className="fas fa-trash  mr-1" />
                                <span>Remove</span>
                              </button>
                            </div>
                          ))
                        : 'No images for this item'}
                    </div>
                    <div className="font-semibold  mt-3">
                      <span>Add new Images</span>
                    </div>
                    <div className="mt-3 flex flex-row items-start">
                      <input
                        type="file"
                        id="file"
                        multiple
                        accept="image/*"
                        name="file"
                        className="outline-none"
                        onChange={handleOnchange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse w-full ">
                <button
                  type="button"
                  className=" inline-flex justify-center mr-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setOpen(false);
                    setOpenDialog(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blueGray-500 text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={(event) => handleSaveImages(event)}
                >
                  Save
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
DialogImagePage.propTypes = {
  titlePage: PropTypes.string,
  setOpenDialog: PropTypes.func,
  pageMedias: PropTypes.array,
  setReload: PropTypes.func
};
