/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/theme/vue.jpg').default,
  title: 'Standard Pack',
  description: '$39',
  line: 'emerald',
  list: ['50 messages', '100 emails/day', 'Community Support'],
  button: {
    children: 'get plan',
    color: 'dark'
  }
};

export default textProps;
