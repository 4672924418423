/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/sections/nathan-dumlao.jpg').default,
  title: 'A Place for Entrepreneurs to Share and Discover New Stories',
  socials: [
    { icon: 'twitter', link: '#pablo' },
    { icon: 'instagram', link: '#pablo' }
  ],
  skew: {
    color: 'white'
  }
};
export default textProps;
