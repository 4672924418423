const textProps = {
  color: 'lightBlue',
  icon: 'fas fa-cog',
  title: 'Social Conversation',
  description:
    'We get insulted by others, lose trust for those others. We get back stabbed by friends. It becomes harder for us to give others a hand.',
  link: {
    children: 'More about us',
    href: '#pablo'
  }
};
export default textProps;
