/* eslint-disable global-require */
const textProps = {
  headImage: require('../../../assets/img/illustrations/linth3.svg').default,
  image: require('../../../assets/img/faces/christian.jpg').default,
  button: {
    children: 'Send Tips',
    color: 'emerald',
    fullWidth: true
  },
  stats: [
    {
      title: '7,800',
      subtitle: 'Finished Rides'
    },
    {
      title: '4.9/5',
      subtitle: 'Stars'
    }
  ],
  title: 'Christian Paisley',
  description: 'New York, USA'
};

export default textProps;
