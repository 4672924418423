/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createPopper } from '@popperjs/core';

const DropdownNavbar = ({ text, items, navColor, handleOnclick, context }) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const [menuClasses, setMenuClasses] = React.useState('');
  const [animating, setAntimating] = React.useState(false);
  const [transformOrigin, setTransformOrigin] = React.useState('origin-top-right');
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const origins = {
    'top-start': 'origin-bottom-left',
    'top-end': 'origin-bottom-right',
    'bottom-start': 'origin-top-left',
    'bottom-end': 'origin-top-right',
    'right-start': 'origin-top-left',
    'right-end': 'origin-top-left',
    'left-start': 'origin-top-right',
    'left-end': 'origin-top-right',
    bottom: 'origin-top',
    top: 'origin-bottom',
    left: 'origin-right',
    right: 'origin-left'
  };
  console.log('items navbar ', items);
  const startAnimation = () => {
    if (!animating) {
      setAntimating(true);
      if (dropdownPopoverShow) {
        setMenuClasses('');
        setTimeout(() => {
          setDropdownPopoverShow(false);
        }, 310);
      } else {
        const popperObject = createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
          placement: 'bottom-start'
        });
        setTransformOrigin(origins[popperObject.state.placement]);
        setDropdownPopoverShow(true);
        setTimeout(() => {
          setMenuClasses('opacity-100 scale-100 ');
        }, 10);
      }
      setTimeout(() => {
        setAntimating(false);
      }, 350);
    }
  };

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event) {
      // console.log('event', event.target);
      // console.log(popoverDropdownRef.current);
      if (
        popoverDropdownRef.current &&
        !popoverDropdownRef.current.contains(event.target) &&
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(event.target)
      ) {
        // console.log('Je suis ici');
        setDropdownPopoverShow(false);
      } // else console.log('event', event.target);
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverDropdownRef, btnDropdownRef]);

  const linkColor = {
    light: 'text-gray-700',
    white: 'text-gray-700',
    transparent: 'text-trueGray-500',
    dark: 'text-gray-700',
    black: 'text-gray-700',
    blueGray: 'text-gray-700',
    red: 'text-gray-700',
    orange: 'text-gray-700',
    amber: 'text-gray-700',
    emerald: 'text-white',
    teal: 'text-white',
    lightBlue: 'text-white',
    indigo: 'text-white',
    purple: 'text-white',
    pink: 'text-white'
  };
  return (
    <>
      <button
        type="button"
        className={`hover:opacity-75 lg:py-3 flex items-center ${
          text !== 'English' && text !== 'German'
            ? context
              ? 'text-sm capitalize'
              : 'text-lg font-bold uppercase'
            : 'text-sm capitalize'
        }   transition-all duration-150 ease-in-out ${
          linkColor[navColor]
        } text-trueGray-500 leading-none px-2 py-2 border border-solid border-warmGray-600 rounded bg-transparent block outline-none focus:outline-none`}
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          startAnimation();
        }}
      >
        {`${text}  `}
        {text !== 'English' && text !== 'German' ? (
          <i
            className={`ml-1 fas ${
              context ? 'fa-chevron-down' : 'fa-bars'
            } transition-all duration-200 ease-in-out transform `}
          />
        ) : (
          <div className="flex flex-col align-bottom  ">
            <i className="ml-1 fas fa-chevron-down transition-all duration-200 ease-in-out transform " />
          </div>
        )}
      </button>
      <div
        ref={popoverDropdownRef}
        className={`${dropdownPopoverShow ? 'block ' : 'hidden '} z-50`}
      >
        <div
          className={`${transformOrigin}  bg-white text-base float-left p-2 border list-none text-left rounded-lg shadow-lg min-w-max transition-all duration-100 ease-in-out transform scale-95 opacity-0  ${menuClasses} lg:grid lg:grid-cols-6 lg:gap-x-3`}
        >
          {items.map((prop, key) => {
            if (prop && prop.disabled) {
              return (
                <span
                  className="uppercase font-bold text-xs px-3 pt-4 block w-full whitespace-nowrap bg-transparent text-blueGray-400"
                  key={key}
                >
                  {prop.children}
                </span>
              );
            }
            if (prop && prop.to) {
              return (
                <div key={key} className="col-span-3">
                  <Link
                    {...prop}
                    key={key}
                    className=" text-sm px-3 py-2 block w-full whitespace-nowrap bg-transparent hover:bg-blueGray-100 rounded transition-all duration-100"
                  />
                </div>
              );
            }
            return (
              <button
                type="button"
                onClick={(event) => {
                  setDropdownPopoverShow(false);
                  handleOnclick(event);
                }}
                {...prop}
                key={key}
                className="text-sm px-3 py-2 block w-max whitespace-nowrap bg-transparent hover:bg-blueGray-100 rounded transition-all duration-100 text-left"
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

DropdownNavbar.defaultProps = {
  items: [],
  context: false
};

DropdownNavbar.propTypes = {
  // text of the dropdown
  text: PropTypes.string,
  context: PropTypes.bool,
  // array of properties to pass to the link object
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  // NOTE: if you pass disabled as prop inside this object
  // // // the link will be rendered as a span element
  items: PropTypes.arrayOf(PropTypes.object),
  handleOnclick: PropTypes.func,
  // you specify the nav color, so that the
  // dropdown text will be styled accordingly
  navColor: PropTypes.oneOf([
    'dark',
    'light',
    'transparent',
    'white',
    'black',
    'blueGray',
    'red',
    'orange',
    'amber',
    'emerald',
    'teal',
    'lightBlue',
    'indigo',
    'purple',
    'pink'
  ])
};

export default DropdownNavbar;
