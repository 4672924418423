const textProps = {
  title: 'Enterprise Offer',
  description:
    'What colors are good, before somebody tells you you shouldn’t like pink because that’s for girls, or you’d instantly become a gay two-year-old.',
  table: [
    ['24/7 Support', 'Design Tools'],
    ['10.000 emails', 'Private Brand']
  ],
  link: {
    href: '#pablo',
    children: 'Contact Us'
  }
};
export default textProps;
