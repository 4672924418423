import MediaPlayerImageLeftRightButtons from './MediaPlayerImageLeftRightButtons.js';
import MediaPlayerTestimonial from './MediaPlayerTestimonial.js';
import MediaPlayerTeamInfo from './MediaPlayerTeamInfo.js';

const componentsArray = [
  MediaPlayerImageLeftRightButtons,
  MediaPlayerTestimonial,
  MediaPlayerTeamInfo
];
export default componentsArray;
