/*eslint-disable*/
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import CartContext from '../../mycomponent/cartContext.js';
import * as ROUTES from '../../constants/routes';
// components
import DropdownNavbar from '../../components/Dropdowns/DropdownNavbar.js';
import {
  getVarious,
  getPagesFrontend,
  getSubcategories,
  getSubcategoriesFront
} from '../../services/api.js';

export default function NavbarLinks({
  leftLinks,
  rightLinks,
  logoImage,
  logoText,
  logoLink,
  socials,
  color,
  type
}) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseStyle, setCollapseStyle] = React.useState(undefined);
  const [animation, setAnimation] = React.useState(false);
  const [bookSet, setBookSet] = React.useState(false);
  const [originalSet, setOriginalSet] = React.useState(false);
  const [japaneseSet, setJapaneseSet] = React.useState(false);
  const [rightLinks_, setRightLinks_] = React.useState([]);
  const collapseRef = React.useRef(null);
  const {
    cart,
    cartTotal,
    addToCart,
    removeFromCart,
    language,
    setLanguage,
    setDevise
  } = useContext(CartContext);
  const startAnitmation = () => {
    if (!animation) {
      setAnimation(true);
      if (collapseOpen) {
        setCollapseStyle(0);
        setTimeout(function () {
          setAnimation(false);
          setCollapseOpen(false);
        }, 310);
      } else {
        setCollapseOpen(true);
        setTimeout(function () {
          setCollapseStyle(collapseRef.current.scrollHeight);
        }, 10);
        setTimeout(function () {
          setAnimation(false);
        }, 310);
      }
    }
  };
  const handleOnclick = (event) => {
    event.preventDefault();
    const name = event.target.name;
    setLanguage(name);
    localStorage.setItem('language', name);
    if (name === 'German') {
      setDevise('CHF');
      localStorage.setItem('devise', 'CHF');
    } else {
      setDevise('USD');
      localStorage.setItem('devise', 'USD');
    }
  };
  let brand = (
    <>
      <img src={logoImage} className="rounded-full mr-2" style={{ width: '30px' }} />
      <span className="text-black">{logoText}</span>
    </>
  );
  const navBg = {
    dark: 'bg-blueGray-800 shadow-md',
    light: 'bg-blueGray-200 shadow-md',
    transparent: 'lg:bg-transparent bg-white',
    white: 'bg-white shadow-md',
    black: 'bg-black shadow-md',
    blueGray: '',
    red: 'bg-red-500 shadow-md',
    orange: 'bg-orange-500 shadow-md',
    amber: 'bg-amber-500 shadow-md',
    emerald: 'bg-emerald-500 shadow-md',
    teal: 'bg-teal-500 shadow-md',
    lightBlue: 'bg-lightBlue-500 shadow-md',
    indigo: 'bg-indigo-500 shadow-md',
    purple: 'bg-purple-500 shadow-md',
    pink: 'bg-pink-500 shadow-md'
  };
  const linkColors = {
    light: 'text-blueGray-500',
    dark: 'text-white',
    transparent: 'text-blueGray-500',
    white: 'text-blueGray-500',
    black: 'text-black',
    blueGray: 'text-blueGray-500',
    red: 'text-white',
    orange: 'text-white',
    amber: 'text-white',
    emerald: 'text-white',
    teal: 'text-white',
    lightBlue: 'text-white',
    indigo: 'text-white',
    purple: 'text-white',
    pink: 'text-white'
  };

  useEffect(() => {
    let mounted = true;
    async function fillPages() {
      const res = await getPagesFrontend();
      const menus = [];
      const menus2 = [];
      if (res.pages) {
        const pages = res.pages;
        for (let i = 0; i < pages.length; i++) {
          if (
            pages[i].title.toLowerCase() !== 'home' &&
            pages[i].title.toLowerCase() !== 'about' &&
            pages[i].title.toLowerCase() !== 'contact' &&
            pages[i].title.toLowerCase() !== 'bespoke'
          ) {
            let arr = [];
            if (language.toLowerCase() === 'english') arr = pages[i].title.split('_');
            else arr = pages[i].titleGerman.split('_');
            for (var b = 0; b < arr.length; b++) {
              arr[b] = arr[b].charAt(0).toUpperCase() + arr[b].slice(1);
            }
            const pageTitle = arr.join(' ');
            const a = {
              to: ROUTES.PAGE + '/' + pages[i].title,
              children: pageTitle
            };
            menus.push(a);
            console.log('menu :', a);
          }
        }

        const res_ = await getSubcategoriesFront();

        if (res_.subcategories) {
          const { subcategories } = res_;
          console.log('subcategories ', subcategories);
          for (let i = 0; i < subcategories.length; i++) {
            if (
              subcategories[i].subcategoryName.toLowerCase() !== 'book' &&
              subcategories[i].subcategoryName.toLowerCase() !== 'various' &&
              subcategories[i].subcategoryName.toLowerCase() !== 'original' &&
              subcategories[i].subcategoryName.toLowerCase() !== 'japanese'
            ) {
              let arr = [];
              if (language.toLowerCase() === 'english')
                arr = subcategories[i].subcategoryName.split('_');
              else arr = subcategories[i].germanName.split('_');
              for (var b = 0; b < arr.length; b++) {
                arr[b] = arr[b].charAt(0).toUpperCase() + arr[b].slice(1);
              }
              const subcategoryName = arr.join(' ');

              const a = {
                children: subcategoryName,
                to: `${ROUTES.DYNAMIC}/${subcategories[i].subcategoryName}`
              };
              menus2.push(a);
              //console.log('menu :', a);
            }
          }
        }

        if (rightLinks) {
          if (rightLinks[1]) {
            const temp = rightLinks.map((a) => a);
            const items = temp[1].items.map((a) => a);

            if (temp[1].items.length <= 7) {
              const pre_array = [];
              pre_array.push(items[0]);
              const res_ = await getSubcategoriesFront();
              if (res_.subcategories) {
                const { subcategories } = res_;
                console.log('subcategories ', subcategories);
                for (let i = 0; i < subcategories.length; i++) {
                  if (subcategories[i].subcategoryName.toLowerCase() === 'book') {
                    setBookSet(true);
                    pre_array.push({
                      to: ROUTES.PRESENTATION_BOOKS,

                      children:
                        language === 'English' ? "Daniel Bürgin's Books" : 'Daniel Bürgin Bücher'
                    });
                    console.log('book ', true);
                  }
                  if (subcategories[i].subcategoryName.toLowerCase() === 'japanese') {
                    setOriginalSet(true);
                    pre_array.push({
                      to: ROUTES.PRESENTATION_JAPANESE,
                      children:
                        language === 'English' ? 'Japanese Collectables' : 'Sammlerstücke aus Japan'
                    });
                    console.log('original ', true);
                  }
                  if (subcategories[i].subcategoryName.toLowerCase() === 'original') {
                    setJapaneseSet(true);
                    pre_array.push({
                      to: ROUTES.PRESENTATION_ORIGINAL,
                      children:
                        language === 'English'
                          ? 'Original Art and Crafts'
                          : 'Kunst und Kunsthandwerk'
                    });
                    console.log('japanese ', true);
                  }
                }
              }
              //console.log('rightlinks ', rightLinks);
              //console.log('rightlinks_ ', rightLinks_);
              //console.log('Pre_array ', pre_array);
              const post_array = [
                {
                  to: ROUTES.BESPOKE,
                  children: language === 'English' ? 'Bespoke search' : 'Suche auf Anfrage'
                },
                {
                  to: ROUTES.ABOUT,
                  children: language === 'English' ? 'About' : 'Über uns'
                },
                {
                  to: ROUTES.CONTACT,
                  children: language === 'English' ? 'Contact' : 'Kontakt'
                }
              ];
              console.log('Post_array ', post_array);
              temp[1].items = [];
              temp[1].items = [...pre_array]
                .concat([...menus])
                .concat([...menus2])
                .concat([...post_array]);
            }
            setRightLinks_(temp);
            //console.log('Rightlinks : ', rightLinks);
          }
        }
      }
    }
    if (mounted) {
      fillPages();
    }
    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, [rightLinks, bookSet, japaneseSet, originalSet]);

  return rightLinks_ && rightLinks_.length > 0 ? (
    <>
      <nav
        className={
          type +
          ' w-full z-90 flex flex-wrap items-center justify-between px-2 lg:px-4 py-3 mb-3 ' +
          navBg[color]
        }
      >
        <div className="container mx-auto  flex flex-row items-center px-0 lg:px-4 ">
          {logoLink && logoLink.to && logoImage ? (
            <Link
              {...logoLink}
              className={
                'text-sm font-bold leading-relaxed inline-flex items-center mr-4 py-2 whitespace-nowrap uppercase ' +
                linkColors[color]
              }
            >
              <img src={logoImage} alt="burgincollection" className="w-9/12" />
            </Link>
          ) : logoLink ? (
            <a
              {...logoLink}
              className={
                'text-sm font-bold leading-relaxed inline-flex items-center mr-4 py-2 whitespace-nowrap uppercase ' +
                linkColors[color]
              }
            >
              {brand}
            </a>
          ) : null}

          <div
            className={classnames(
              ' items-center flex lg:flex lg:w-auto ml-auto  duration-300 transition-all ease-in-out col-span-9',
              {
                'overflow-hidden': animation
              }
            )}
            style={{
              height: collapseStyle
            }}
            ref={collapseRef}
          >
            <ul className="items-center ml-auto flex flex-wrap list-none pl-0 mb-0 ">
              <li className="mr-1">
                <Link
                  to={ROUTES.CART}
                  className={
                    'relative hover:opacity-75 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold transition-all duration-150 ease-in-out ' +
                    linkColors[color]
                  }
                >
                  {cart && cart.length > 0 && (
                    <div className="fas fa-shopping-cart">
                      <div className="absolute top-0 right-0 text-white text-xs p-1 leading-none bg-red-500 h-3 w-3 rounded-full">
                        {cart.length}
                      </div>
                    </div>
                  )}
                </Link>
              </li>
              {rightLinks_.map((prop, key) => {
                if (prop.to && prop.icon) {
                  return (
                    <li key={key} className="mr-1">
                      <Link
                        {...prop}
                        className={
                          'hover:opacity-75 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold transition-all duration-150 ease-in-out ' +
                          linkColors[color]
                        }
                      >
                        <i className={prop.icon + ' text-black'} />
                      </Link>
                    </li>
                  );
                } else if (prop.to) {
                  <li key={key}>
                    <Link
                      {...prop}
                      className={
                        'hover:opacity-75 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-150 ease-in-out ' +
                        linkColors[color]
                      }
                    />
                  </li>;
                } else if (prop.dropdown) {
                  return (
                    <li className="relative mr-2" key={key}>
                      <DropdownNavbar
                        {...prop}
                        text={prop.text === 'English' ? language : prop.text}
                        navColor={color}
                        handleOnclick={handleOnclick}
                      />
                    </li>
                  );
                } else {
                  return (
                    <li key={key}>
                      <a
                        {...prop}
                        className={
                          'hover:opacity-75 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold transition-all duration-150 ease-in-out ' +
                          linkColors[color]
                        }
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  ) : null;
}
NavbarLinks.defaultProps = {
  leftLinks: [],
  rightLinks: [],
  socials: [],
  type: ''
};
NavbarLinks.propTypes = {
  logoImage: PropTypes.string,
  logoText: PropTypes.string,
  // properties to pass to the link object wrapping
  // the logoText and logoImage
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  logoLink: PropTypes.object,
  color: PropTypes.oneOf([
    'dark',
    'light',
    'transparent',
    'white',
    'black',
    'blueGray',
    'red',
    'orange',
    'amber',
    'emerald',
    'teal',
    'lightBlue',
    'indigo',
    'purple',
    'pink'
  ]),
  type: PropTypes.oneOf(['absolute', 'fixed', 'relative']),
  // array of properties (for both, left and right Links)
  // to pass to the link object
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  // NOTE: you can generate a dropdown if, inside the
  // // // object you pass dropdown: true as a prop
  // // // please check the DropdownNavbar to see
  // // // what the object should be in this case
  leftLinks: PropTypes.arrayOf(PropTypes.object),
  rightLinks: PropTypes.arrayOf(PropTypes.object),
  socials: PropTypes.arrayOf(
    // this will generate an anchor with target blank to the given link
    PropTypes.shape({
      icon: PropTypes.oneOf(['facebook', 'twitter', 'instagram', 'dribbble', 'github']),
      link: PropTypes.string,
      // this will be visible only on mobile devices
      text: PropTypes.string
    })
  )
};
