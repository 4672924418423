import dropdownuser from '../../misc/dropdowns/dropdownuser.js';

const textProps = {
  brand: {
    href: '#pablo',
    children: 'Settings Page'
  },
  input: {
    placeholder: 'Search here',
    border: 'borderless',
    type: 'text'
  },
  dropdown: { ...dropdownuser }
};
export default textProps;
