import card from '../cards/cardsocialmedia.js';

const textProps = {
  cards: [
    {
      ...card
    },
    {
      icon: 'fab fa-facebook-f',
      title: 'facebook',
      subtitle: '@creative-tim',
      color: 'facebook',
      link: {
        href: '#pablo'
      }
    },
    {
      icon: 'fab fa-slack',
      title: 'slack',
      subtitle: '@creative-tim',
      color: 'slack',
      link: {
        href: '#pablo'
      }
    },
    {
      icon: 'fab fa-instagram',
      title: 'instagram',
      subtitle: '@creative-tim',
      color: 'instagram',
      link: {
        href: '#pablo'
      }
    }
  ]
};
export default textProps;
