/* eslint-disable global-require */
const textProps = {
  defaultOpened: 0,
  items: [
    require('../../../assets/img/pages/shirt.png').default,
    require('../../../assets/img/pages/shorts.png').default,
    require('../../../assets/img/pages/tshirt.png').default
  ]
};
export default textProps;
