const textProps = {
  icon: 'fas fa-check',
  color: 'lightBlue',
  title: 'Download Design',
  description:
    'Because I’m here to follow my dreams and inspire other people to follow their dreams, too. That’s what I’m here for.',
  button: {
    color: 'lightBlue',
    children: 'Learn more'
  }
};

export default textProps;
