/* eslint-disable global-require */
const textProps = {
  headImage: require('../../../assets/img/illustrations/inn.svg').default,
  image: require('../../../assets/img/faces/face-2.jpg').default,
  leftButton: {
    children: 'Connect',
    color: 'dark',
    size: 'sm'
  },
  rightButton: {
    children: 'Message',
    color: 'dark',
    size: 'sm'
  },
  stats: [
    {
      title: '5,390',
      subtitle: 'Followers'
    },
    {
      title: '560',
      subtitle: 'Photos'
    },
    {
      title: '890',
      subtitle: 'Projects'
    }
  ],
  title: 'Melanie Tompson',
  description: 'Beijing, China'
};

export default textProps;
