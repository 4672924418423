const addPageProps = {
  title: 'Adding Page Information',
  buttonCancel: { children: 'Cancel', size: 'sm', color: 'red' },
  button: { children: 'Save page', size: 'sm', color: 'lightBlue' },
  forms: [
    {
      title: 'Page details',
      inputs: [
        {
          label: 'title',
          width: 6,
          input: {
            placeholder: 'page title',
            type: 'text',
            defaultValue: '',
            name: 'title'
          }
        },
        {
          label: 'German title',
          width: 6,
          input: {
            placeholder: 'German page title',
            type: 'text',
            defaultValue: '',
            name: 'titleGerman'
          }
        },
        {
          label: 'heading',
          width: 6,
          input: {
            placeholder: 'page heading',
            type: 'text',
            defaultValue: '',
            name: 'heading'
          }
        },
        {
          label: 'German heading',
          width: 6,
          input: {
            placeholder: 'page german heading',
            type: 'text',
            defaultValue: '',
            name: 'headingGerman'
          }
        },
        {
          label: 'English Description',
          width: 12,
          input: {
            placeholder: 'Your english description here',
            type: 'textarea',
            defaultValue: '',
            rows: 7,
            name: 'descriptionEnglish'
          }
        },
        {
          label: 'German Description',
          width: 12,
          input: {
            placeholder: 'Your german description here',
            type: 'textarea',
            defaultValue: '',
            rows: 7,
            name: 'descriptionGerman'
          }
        }
      ]
    }
  ]
};
export default addPageProps;
