/* eslint-disable global-require */
const textProps = {
  head: 'Freelancer',
  title: '$99',
  list: [
    {
      title: '1',
      description: 'Team Member'
    },
    {
      title: '15',
      description: 'Private Projects'
    },
    {
      title: '25',
      description: 'Elements of Design'
    },
    {
      title: '1.000',
      description: 'Commits/month'
    }
  ],
  image: require('../../../assets/img/illustrations/pricing_bg.svg').default,
  button: {
    children: 'get started',
    size: 'sm',
    color: 'white'
  },
  color: 'lightBlue'
};

export default textProps;
