const textProps = {
  icon: 'fas fa-user-friends',
  iconColor: 'blueGray',
  title: 'Working with us is a pleasure',
  description: [
    "Don't let your users guess by attaching tooltips and popoves to any element. Just make sure you enable them first via JavaScript.",
    "The kit comes with three pre-built pages to help you get started faster. You can change the text and images and you're good to go. Just make sure you enable them first via JavaScript."
  ],
  link: {
    text: 'Check Notus PRO React!',
    link: '/',
    innerLink: true
  }
};
export default textProps;
