/* eslint-disable global-require */
import * as ROUTES from '../constants/routes';

const textProps = {
  brand: {
    text: 'Burgin Collection',
    image: require('../assets/img/brand/burgin_logo.png').default,
    link: {
      to: ROUTES.DASHBOARD
    }
  },
  activeColor: 'lightBlue',
  items: [
    {
      divider: true
    },
    {
      title: 'Web Contents'
    },
    {
      icon: 'fas fa-globe',
      text: 'Website Pages',
      active: false,
      link: {
        to: ROUTES.DASHBOARD
      }
    },
    {
      icon: 'fas fa-book-open',
      text: 'Books',
      active: false,
      link: {
        to: ROUTES.BOOKS
      }
    },

    {
      icon: 'fas fa-democrat',
      text: 'Original Arts and crafts',
      active: false,
      link: {
        to: ROUTES.ORIGINAL_ARTS
      }
    },
    {
      icon: 'fas fa-gem',
      text: 'Japanese collectables',
      active: false,
      link: {
        to: ROUTES.JAPANESE_COLLECTABLES
      }
    },
    {
      divider: true
    },
    {
      title: 'Inquiries'
    },
    {
      icon: 'fas fa-envelope',
      text: 'Messages',
      active: false,
      link: {
        to: ROUTES.MESSAGES
      }
    },
    {
      icon: 'fas fa-search',
      text: 'Bespokes Search',
      active: false,
      link: {
        to: ROUTES.ADMIN_BESPOKE
      }
    },
    {
      icon: 'fas fa-shopping-cart',
      text: 'Orders',
      active: false,
      link: {
        to: ROUTES.ORDERS
      }
    }
  ]
};
export default textProps;
