export const ADMIN_LOGIN = '/admin/login';
export const DASHBOARD = '/admin/dashboard';
export const ADD_PAGE = '/admin/addpage';
export const BOOKS = '/admin/books';
export const VARIOUS = '/admin/various';
export const ORIGINAL_ARTS = '/admin/original_arts';
export const JAPANESE_COLLECTABLES = '/admin/japanese_collectables';
export const MESSAGES = '/admin/messages';
export const ORDERS = '/admin/orders';
export const ADMIN_BESPOKE = '/admin/bespokes';
export const ADMIN_DYNAMIC = '/adminCategory/:dynamicCategory';
export const ADMIN = '/adminCategory';
export const HOME = '/home';
export const PAGE = '/page';
export const DYNAMIC_PAGE = '/page/:pageName';
export const PRESENTATION_BOOKS = '/books';
export const CATEGORY_BOOKS = '/books/:bookCategory';
export const BOOK_DETAIL = '/books/:bookCategory/:productParam';
export const ITEM_DETAIL = '/:category/:subCategory/:productParam';
export const PRESENTATION_VARIOUS = '/various';
export const DYNAMIC = '/category';
export const DYNAMIC_CATEGORY = '/category/:newCategory';
export const DYNAMIC_PRODUCT = '/category/:newCategory/:newProduct';
export const CATEGORY_VARIOUS = '/various/:variousCategory';
export const VARIOUS_DETAIL = '/various/:variousCategory/:productParam';
export const PRESENTATION_ORIGINAL = '/original_arts';
export const CATEGORY_ORIGINAL = '/original_arts/:originalCategory';
export const ORIGINAL_DETAIL = '/original_arts/:originalCategory/:productParam';
export const PRESENTATION_JAPANESE = '/japanese_collectables';
export const CATEGORY_JAPANESE = '/japanese_collectables/:japaneseCategory';
export const JAPANESE_DETAIL = '/japanese_collectables/:japaneseCategory/:productParam';
export const ABOUT = '/about';
export const ARTISTS = '/artists';
export const CONTACT = '/contact';
export const BESPOKE = '/bespoke';
export const BLOG = '/blog';
export const LOGIN = '/login';
export const SIGNIN = '/signin';
export const CART = '/cart';
export const CHECKOUT = '/checkout';
export const DASHBOARD_PRESENTATION = '/mydashboard';
