const textProps = {
  title: 'Thank you for supporting us!',
  subtitle: "Let's get in touch on any of these platforms.",
  socials: [
    {
      icon: 'fab fa-twitter',
      buttonProps: {
        color: 'white',
        outline: false,
        size: 'sm'
      }
    },
    {
      icon: 'fab fa-facebook',
      buttonProps: {
        color: 'white',
        outline: false,
        size: 'sm'
      }
    },
    {
      icon: 'fab fa-dribbble',
      buttonProps: {
        color: 'white',
        outline: false,
        size: 'sm'
      }
    },
    {
      icon: 'fab fa-github',
      buttonProps: {
        color: 'white',
        outline: false,
        size: 'sm'
      }
    }
  ]
};
export default textProps;
