import React from 'react';
import dropdownnavbar from '../../misc/dropdowns/dropdownnavbar.js';
import * as ROUTES from '../../../constants/routes';

const textProps = {
  color: 'transparent',
  type: 'absolute',
  logoText: 'Burgin Collection',
  logoLink: { to: '/' },
  rightLinks: [
    {
      dropdown: true,
      text: 'Menu',
      items: [
        {
          to: ROUTES.HOME,
          children: 'Home'
        },
        {
          to: ROUTES.PRESENTATION_BOOKS,

          children: 'Books'
        },
        {
          to: ROUTES.PRESENTATION_VARIOUS,
          children: 'Various'
        },
        {
          to: ROUTES.PRESENTATION_JAPANESE,
          children: 'Japanese Collectables'
        },
        {
          to: ROUTES.PRESENTATION_ORIGINAL,
          children: 'Original Art and Crafts'
        },
        {
          to: ROUTES.BESPOKE,
          children: 'Bespoke search'
        },
        {
          to: ROUTES.ARTISTS,
          children: 'About the Artists'
        },
        {
          to: ROUTES.ABOUT,
          children: 'About'
        },
        {
          to: ROUTES.CONTACT,
          children: 'Contact'
        }
      ]
    }
  ]
};
export default textProps;
