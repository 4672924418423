/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/team-1-800x800.jpg').default,
  name: 'Ryan Tompson',
  jobTitle: 'Web Developer',
  socialLinks: [
    {
      icon: 'twitter',
      link: '#pablo'
    },
    {
      icon: 'facebook',
      link: '#pablo'
    },
    {
      icon: 'dribbble',
      link: '#pablo'
    }
  ]
};
export default textProps;
