/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/pages/fashion-show.jpg').default,
  title: 'Check our latest items!',
  text:
    'Shop now and find your special suits, t-shirts and trousers. Many designers just launched their Autumn/Winter 2021 Collection!',
  icon: 'fas fa-shopping-cart',
  color: 'red',
  skew: {
    color: 'white'
  }
};
export default textProps;
