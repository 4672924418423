/* eslint-disable global-require */
const textProps = {
  video: require('../../../assets/videos/Lights - 26607.mp4').default,
  title: 'Welcome to our most special company',
  subtitle:
    "We’re not always in the position that we want to be at. We’re constantly growing! When you're home, is different, since your parents are helping you.",
  buttonColor: 'red',
  buttonText: 'Get in contact',
  buttonIcon: 'fas fa-play',
  skew: {
    color: 'white'
  }
};
export default textProps;
