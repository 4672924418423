const textProps = {
  size: 'sm',
  color: 'pink',
  links: [
    {
      children: '1',
      href: '#pablo',
      active: true
    },
    {
      children: '2',
      href: '#pablo'
    },
    {
      children: '3',
      href: '#pablo'
    },
    {
      children: '4',
      href: '#pablo'
    },
    {
      children: '...',
      href: '#pablo',
      disabled: true
    }
  ]
};

export default textProps;
