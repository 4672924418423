const textProps = {
  copyright: {
    href: 'https://www.creative-tim.com/?ref=npr-footeradmin',
    target: '_blank',
    children: `Copyright © ${new Date().getFullYear()} Creative Tim`
  },
  links: [
    {
      href: 'https://www.creative-tim.com?ref=npr-footeradmin',
      target: '_blank',
      children: 'Creative Tim'
    },
    {
      href: 'https://www.creative-tim.com/presentation?ref=npr-footeradmin',
      target: '_blank',
      children: 'About Us'
    },
    {
      href: 'https://www.creative-tim.com/blog/?ref=npr-footeradmin',
      target: '_blank',
      children: 'Blog'
    },
    {
      href: 'https://www.creative-tim.com/license?ref=npr-footeradmin',
      target: '_blank',
      children: 'Licenses'
    }
  ]
};
export default textProps;
