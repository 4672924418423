/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';

export default function useUser() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getUser() {
      const user = JSON.parse(localStorage.getItem('authUser'));
      const authTime = localStorage.getItem('authTime');
      console.log('User in hook use user here ', user);
      console.log('Time in hook use user here ', authTime);
      if (user) {
        if (new Date().getTime() - new Date(authTime).getTime() < 1000 * 30 * 24 * 3600) {
          console.log('use set well', new Date(authTime));
          setUser(user);
        } else setUser(null);
      } else setUser(null);
    }
    // console.log('User in hook use user ', userId);
    getUser();
  }, []);
  return { user, setUser };
}
