const textProps = {
  heading3badge: {
    badge: { color: 'emerald', children: 'Special options' },
    title: 'Packages for your team',
    description:
      'I was dreaming about money, money, money. I would look at the ceiling and I would only see money, money, money.',
    alignment: 'right'
  },
  table: {
    head: [
      'Space Individual',
      {
        title: '$39',
        subtitle: 'Team',
        button: {
          children: 'choose plan',
          color: 'emerald',
          outline: true,
          size: 'sm'
        }
      },
      {
        title: '$79',
        subtitle: 'Growth',
        button: {
          children: 'choose plan',
          color: 'emerald',
          outline: true,
          size: 'sm'
        }
      },
      {
        title: '$99',
        subtitle: 'Enterprise',
        button: {
          children: 'choose plan',
          color: 'emerald',
          size: 'sm'
        }
      }
    ],
    body: [
      [
        'Community support',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        }
      ],
      [
        '100+ Example Pages',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        }
      ],
      [
        '50+ Section Examples',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        }
      ],
      [
        'Multiple use',
        {
          icon: 'fas fa-times-circle',
          color: 'red'
        },
        'upon request',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        }
      ],
      [
        'Custom messages',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald'
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red'
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red'
        }
      ],
      [
        'Multiple requests',
        {
          icon: 'fas fa-times-circle',
          color: 'red'
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red'
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red'
        }
      ]
    ]
  }
};

export default textProps;
