import React, { useContext, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

// components
import ContentPages from '../../mycomponent/AdminContent/contentPages.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import FooterAdmin from '../../components/Footers/Admin/FooterAdmin.js';
// texts as props
import sidebar from '../../content/sidebar.js';
import footeradmin from '../../_texts/admin/footers/footeradmin.js';
import * as ROUTES from '../../constants/routes';
import CartContext from '../../mycomponent/cartContext.js';

export default function Dashboard() {
  const location = useLocation();
  const { admin, setAdmin } = useContext(CartContext);
  const [redirectLogin, setRedirectLogin] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!admin) {
      setRedirectLogin(true);
    }
  }, [location]);
  return (
    <>
      <Sidebar {...sidebar} />
      <div className="relative md:ml-64 min-h-screen bg-blueGray-100">
        <ContentPages />
        {false && (
          <div className="px-4 md:px-6 mx-auto w-full -mt-24">
            <FooterAdmin {...footeradmin} />
          </div>
        )}
        {redirectLogin ? <Redirect push to={ROUTES.ADMIN_LOGIN} /> : null}
      </div>
    </>
  );
}
