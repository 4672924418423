import infoareaicontitlelistcolor from '../../presentation/infoarea/infoareaicontitlelistcolor.js';

const textProps = {
  heading2: {
    color: 'emerald',
    subtitle: 'Time passage',
    title: 'In the years that have passed',
    description:
      'I know I should have got more than I have and had, I know that not very nice people have stolen from us a lot, but I also know that people want to hear about Notus PRO React.',
    alignment: 'right'
  },
  infoArea: { ...infoareaicontitlelistcolor },
  cards: [
    {
      color: 'teal',
      icon: 'fas fa-smile-beam',
      title: 'Best Quality',
      description:
        'What matters is the people who are sparked by it. And the people who are like offended by it.',
      gradient: false
    },
    {
      color: 'red',
      icon: 'fas fa-rocket',
      title: 'Awesome Desgin',
      description:
        "Because it's about motivating the doers. Because I’m here to follow my dreams and inspire the world.",
      gradient: false
    },
    {
      color: 'dark',
      icon: 'fab fa-avianex',
      title: 'Great Performane',
      description:
        'There’s nothing I really wanted to do in life that I wasn’t able to get good at. We’re constantly growing.',
      gradient: false
    },
    {
      color: 'lightBlue',
      icon: '',
      title: 'Fast Development',
      description:
        'I always felt like I could do anything. That’s the main thing people are controlled by the force!',
      gradient: false
    },
    {
      color: 'orange',
      icon: 'fas fa-glasses',
      title: 'Super Fresh',
      description:
        "Thoughts- their perception of themselves! They're slowed down by their perception of themselves.",
      gradient: false
    },
    {
      color: 'emerald',
      icon: 'fas fa-sitemap',
      title: 'Organized Content',
      description:
        "If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything.",
      gradient: false
    }
  ]
};

export default textProps;
