import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { green, red } from 'tailwindcss/colors';
import CardAddPage from '../../components/Cards/Admin/CardAddPage';
import addPageProps from '../../content/addPage';
import addCategoryProps from '../../content/addCategory';

import * as ROUTES from '../../constants/routes';
import {
  getPages,
  registerCategory,
  registerPage,
  updatePage,
  updatePageActive
} from '../../services/api';
import CardTablePage from '../../components/Cards/Admin/cardTablePage';
import CardEditPage from '../../components/Cards/Admin/CardEditPage';
import CardAddCategory from '../../components/Cards/Admin/CardAddCategory';
import DialogImagePage from './dialogImagePages';

export default function ContentPages() {
  const [addPage, setAddPage] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [titlePage, setTitlePage] = useState('');
  const [germanTitlePage, setGermanTitlePage] = useState('');
  const [descriptionEnglish, setDescriptionEnglish] = useState('');
  const [descriptionGerman, setDescriptionGerman] = useState('');
  const [message, setMessage] = useState('');
  const [heading, setHeading] = useState('');
  const [headingGerman, setHeadingGerman] = useState('');
  const [error, setError] = useState(false);
  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [myPage, setMyPage] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    let mounted = true;
    async function fillPages() {
      const res = await getPages();
      if (res.pages) {
        setPages(res.pages);
        console.log('My pages', res.pages);
        setReload(false);
      }
    }
    if (mounted) {
      fillPages();
    }
    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, [reload]);

  const handleInput = (event) => {
    event.preventDefault();
    // console.log(event.target.value);
    if (event.target.name === 'title') {
      setTitlePage(event.target.value);
    }
    if (event.target.name === 'titleGerman') {
      setGermanTitlePage(event.target.value);
    }
    if (event.target.name === 'descriptionEnglish') {
      setDescriptionEnglish(event.target.value);
    }
    if (event.target.name === 'descriptionGerman') {
      setDescriptionGerman(event.target.value);
    }
    if (event.target.name === 'heading') {
      setHeading(event.target.value);
    }
    if (event.target.name === 'headingGerman') {
      setHeadingGerman(event.target.value);
    }
  };

  const handleControl = (event, page) => {
    event.preventDefault();
    // console.log(event.target.value);
    if (event.target.name === 'editPage') {
      setTitlePage(page.title);
      setDescriptionEnglish(page.descriptionEnglish);
      setDescriptionGerman(page.descriptionGerman);
      setHeading(page.heading);
      setHeadingGerman(page.headingGerman);
      setGermanTitlePage(page.titleGerman);
      setEditPage(true);
    }
    if (event.target.name === 'viewPage') {
      console.log('view', event.target);
    }
    if (event.target.name === 'deletePage') {
      console.log('delete', event.target);
    }
    if (event.target.name === 'addImage') {
      setOpenDialog(true);
      setMyPage(page);
    }
  };

  const handleSavePage = async (event) => {
    event.preventDefault();
    setError(false);
    setMessage('');
    if (event.target.name === 'cancel') {
      setEditPage(false);
      setAddPage(false);
    } else {
      const res = await registerPage(
        titlePage,
        descriptionEnglish,
        descriptionGerman,
        heading,
        headingGerman,
        germanTitlePage
      );
      if (res.message) {
        setMessage(res.message);
        setError(false);
        setAddPage(false);
        setTitlePage('');
        setDescriptionGerman('');
        setDescriptionEnglish('');
        setGermanTitlePage('');
        setHeading('');
        setHeadingGerman('');
        const res_ = await getPages();
        if (res_.pages) {
          setPages(res_.pages);
          console.log('My pages', res_.pages);
        }
      } else if (res.error) {
        setMessage(res.error);
        setError(true);
      }
    }
  };
  const handleEditPage = async (event) => {
    event.preventDefault();
    setError(false);
    setMessage('');
    if (event.target.name === 'cancel') {
      setEditPage(false);
      setAddPage(false);
    } else {
      const res = await updatePage(
        titlePage,
        descriptionEnglish,
        descriptionGerman,
        heading,
        headingGerman,
        germanTitlePage
      );
      if (res.message) {
        setMessage(res.message);
        setError(false);
        setAddPage(false);
        setEditPage(false);
        setTitlePage('');
        setDescriptionGerman('');
        setDescriptionEnglish('');
        setGermanTitlePage('');
        setHeading('');
        setHeadingGerman('');
        const res_ = await getPages();
        if (res_.pages) {
          setPages(res_.pages);
          console.log('My pages', res_.pages);
        }
      } else if (res.error) {
        setMessage(res.error);
        setError(true);
      }
    }
  };

  const handleSaveCategory = async (event) => {
    event.preventDefault();
    setError(false);
    setMessage('');
    if (event.target.name === 'cancel') {
      setEditPage(false);
      setAddPage(false);
      setAddCategory(false);
    } else {
      const res = await registerCategory(titlePage, heading, headingGerman, germanTitlePage);
      if (res.message) {
        setMessage(res.message);
        setError(false);
        setAddPage(false);
        setTitlePage('');
        setDescriptionGerman('');
        setDescriptionEnglish('');
        setGermanTitlePage('');
        setHeading('');
        setHeadingGerman('');
        setAddCategory(false);
      } else if (res.error) {
        setMessage(res.error);
        setError(true);
      }
    }
  };

  const handleChangeState = async (
    event,
    page,
    name,
    checkedPrice,
    checkedCover,
    checkedAvailability,
    checkedActive
  ) => {
    console.log('Pageee : ', page);
    if (name === 'setActive') {
      const shActive = checkedActive ? 0 : 1;
      const res = await updatePageActive(
        page.title,
        page.descriptionEnglish,
        page.descriptionGerman,
        page.heading,
        page.headingGerman,
        page.titleGerman,
        shActive
      );
      if (res.message) {
        setMessage(res.message);
        setError(false);
        setAddPage(false);
        setEditPage(false);
        setTitlePage('');
        setDescriptionGerman('');
        setDescriptionEnglish('');
        setGermanTitlePage('');
        setHeading('');
        setHeadingGerman('');
        const res_ = await getPages();
        if (res_.pages) {
          setPages(res_.pages);
          // console.log('My pages', res_.pages);
        }
      }
    }
  };

  return (
    <div className="px-4 md:px-6 mx-auto w-full pt-12 ">
      <button
        type="button"
        className="flex flex-row  mb-6 outline-none focus:outline-none text-lightBlue-600 hover:text-lightBlue-900"
        onClick={() => {
          setAddPage(true);
          setError(false);
          setMessage('');
          setEditPage(false);
          setAddCategory(false);
        }}
      >
        <div className="flex flex-col align-middle">
          <div className="my-auto">
            <i className="fas fa-plus-circle mr-2 my-auto" />
          </div>
        </div>
        <span className="uppercase font-semibold my-auto">Add page information</span>
      </button>
      <button
        type="button"
        className="flex flex-row  mb-6 outline-none focus:outline-none text-lightBlue-600 hover:text-lightBlue-900"
        onClick={() => {
          setAddPage(false);
          setError(false);
          setMessage('');
          setEditPage(false);
          setAddCategory(true);
        }}
      >
        <div className="flex flex-col align-middle">
          <div className="my-auto">
            <i className="fas fa-plus-circle mr-2 my-auto" />
          </div>
        </div>
        <span className="uppercase font-semibold my-auto">Add Category</span>
      </button>
      {message.length !== 0 && (
        <p
          className={`text-base ${
            error ? 'text-red-600' : 'text-green-600'
          } pb-4 ml-4 pl-2 font-semibold`}
        >
          <i className="fas fa-check mr-1" />
          <span>{message}</span>
        </p>
      )}
      {addPage && (
        <div className="w-full md:w-10/12">
          <CardAddPage
            {...addPageProps}
            handleInput={handleInput}
            handleSavePage={handleSavePage}
            category=""
          />
        </div>
      )}
      {!addPage && !editPage && !addCategory && (
        <div className="w-full mb-12 px-4">
          <CardTablePage
            body={pages}
            handleControl={handleControl}
            handleChangeState={handleChangeState}
          />
        </div>
      )}
      {editPage && (
        <div className="w-full md:w-10/12">
          <CardEditPage
            {...addPageProps}
            handleInput={handleInput}
            handleEditPage={handleEditPage}
            titlePage={titlePage}
            descriptionEnglish={descriptionEnglish}
            descriptionGerman={descriptionGerman}
            heading={heading}
            headingGerman={headingGerman}
            titleGerman={germanTitlePage}
            category=""
          />
        </div>
      )}
      {addCategory && (
        <div className="w-full md:w-10/12">
          <CardAddCategory
            {...addCategoryProps}
            handleInput={handleInput}
            handleSaveCategory={handleSaveCategory}
            titlePage={titlePage}
            descriptionEnglish={descriptionEnglish}
            descriptionGerman={descriptionGerman}
            heading={heading}
            headingGerman={headingGerman}
            titleGerman={germanTitlePage}
            category=""
            addCategory={addCategory}
          />
        </div>
      )}
      {openDialog ? (
        <DialogImagePage
          titlePage={myPage.title}
          setOpenDialog={setOpenDialog}
          pageMedias={myPage.medias}
          setReload={setReload}
        />
      ) : null}
    </div>
  );
}
