const textProps = {
  color: 'pink',
  subtitle: 'Autobots vs Decepticons',
  title: 'You may need an Autobot in your life',
  description:
    "I always felt like I could be a Decepticon. Like their name's saying, they are good at deception. But then, I thought they are bad, and maybe I would be better as an Autobot instead.",
  alignment: 'left'
};

export default textProps;
