import navbarlinkslanding1 from '../../presentation/navbars/navbarlinkslanding1.js';
import headerimagetitlesmallcenter from '../headers/headerimagetitlesmallcenter.js';
import prefooterlarge from '../../presentation/prefooters/prefooterlarge.js';
import footersmall from '../../presentation/footers/footersmall.js';

const textProps = {
  navbar: { ...navbarlinkslanding1 },
  header: { ...headerimagetitlesmallcenter },
  prefooter: { ...prefooterlarge },
  footer: { ...footersmall }
};
export default textProps;
