import mediaplayertestimonial from '../mediaplayers/mediaplayertestimonial.js';

const textProps = {
  heading2: {
    color: 'red',
    subtitle: 'See our customers opinion',
    title: 'Join our world',
    description:
      'Meet Wingman, a robust suite of styled pages and components, powered by Bootstrap 4. The ideal starting point for product landing pages, stylish web-apps and complete company websites.',
    alignment: 'left'
  },
  button: {
    color: 'pink',
    children: 'Contact us'
  },
  mediaplayer: {
    ...mediaplayertestimonial
  }
};
export default textProps;
