/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import useDevise from './useDevise';

export default function useShopingCart() {
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [devise, setDevise] = useState(localStorage.getItem('devise'));

  const getPrice = (item) => {
    const _devise = localStorage.getItem('devise');
    setDevise(_devise);
    if (devise === 'USD') return item.price;
    if (devise === 'CHF') return item.priceSwiss;
    if (devise === 'EUR') return item.priceEuro;
    if (devise === 'JPY') return item.priceJapan;
  };

  const total = () => {
    let totalVal = parseFloat('0');
    for (let i = 0; i < cart.length; i++) {
      totalVal += parseFloat(getPrice(cart[i]));
    }
    setCartTotal(totalVal);
    console.log('compute Total cart', `${totalVal} ${devise}`);
  };
  useEffect(() => {
    total();
  }, [cart, devise]);

  const addToCart = (el) => {
    setCart([...cart, el]);
  };

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    hardCopy = hardCopy.filter((cartItem) => cartItem.productId !== el.productId);
    setCart(hardCopy);
  };
  const emptyCart = () => {
    setCart([]);
  };
  return { cart, cartTotal, addToCart, removeFromCart, emptyCart, total };
}
