const textProps = {
  icon: 'fas fa-hat-wizard',
  color: 'lightBlue',
  title: 'Modular Components',
  description:
    'The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer, and that process will continue whatever and whatever.',
  link: {
    href: '#pablo',
    children: 'Learn more'
  }
};

export default textProps;
