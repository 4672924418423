/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';

export default function useDevise() {
  const [devise, setDevise] = useState('USD');

  useEffect(() => {
    async function getLanguage() {
      const _devise = localStorage.getItem('devise');
      if (_devise) {
        setDevise(_devise);
      }
    }
    // console.log('User in hook use user ', userId);
    getLanguage();
  }, [devise]);
  return { devise, setDevise };
}
