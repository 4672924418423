/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CartContext from '../../../mycomponent/cartContext';
// components

// eslint-disable-next-line react/prop-types
export default function CardBlogFullBg({
  image,
  title,
  subtitleGerman,
  subtitle,
  link,
  rounded,
  size
}) {
  const { language } = useContext(CartContext);
  const sizes = {
    sm: 'h-330-px',
    md: 'h-350-px',
    lg: 'h-530-px'
  };
  const body = (
    <div className="absolute text-left p-6 bottom-0">
      <h6 className="text-xl leading-normal mb-0 text-white opacity-75">
        {language === 'English' ? subtitle : subtitleGerman}
      </h6>
      <h5 className="text-2xl font-bold leading-tight mt-0 mb-2 text-white">{title}</h5>
    </div>
  );
  return (
    <>
      {link && link.to ? (
        <Link
          {...link}
          className={`${
            sizes[size]
          } overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg transition-all duration-150 ease-in-out hover:z-1 hover:transform hover:scale-110 group ${
            rounded ? ' rounded-lg mb-10' : ''
          }`}
        >
          <div
            className={`absolute w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110${
              rounded ? ' rounded-lg' : ''
            }`}
            style={{
              backgroundImage: `url('${image}')`,
              backfaceVisibility: 'hidden'
            }}
          />
          <div
            className={`absolute w-full h-full bg-black opacity-50${rounded ? ' rounded-lg' : ''}`}
          />
          <div>{body}</div>
        </Link>
      ) : (
        <div
          className={`${
            sizes[size]
          } overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg transition-all duration-150 ease-in-out hover:z-1 hover:transform hover:scale-110 group ${
            rounded ? ' rounded-lg mb-10' : ''
          }`}
        >
          <div
            className={`absolute w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110${
              rounded ? ' rounded-lg' : ''
            }`}
            style={{
              backgroundImage: `url('${image}')`,
              backfaceVisibility: 'hidden'
            }}
          />
          <div
            className={`absolute w-full h-full bg-black opacity-50${rounded ? ' rounded-lg' : ''}`}
          />
          <div>{body}</div>
        </div>
      )}
    </>
  );
}
CardBlogFullBg.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  innerLink: false,
  rounded: true,
  size: 'md'
};

CardBlogFullBg.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitleGerman: PropTypes.string,
  subtitle: PropTypes.string,
  // if it should have rounded coreners or not
  rounded: PropTypes.bool,
  // properties to pass to the link object
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  link: PropTypes.object,
  // this is for the height
  size: PropTypes.oneOf(['sm', 'lg', 'md'])
};
