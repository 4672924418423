import accordion from '../accordions/accordion.js';

const textProps = {
  heading2: {
    color: 'teal',
    subtitle: 'Why, though',
    title: 'Why you speak of me?',
    description:
      "I wonder if you would have done the same when I had to beg at people's doors? I wonder if you would have felt the same as I did when I was chased from people's doors?",
    alignment: 'center'
  },
  accordion: { ...accordion }
};

export default textProps;
