import navbarlinkslanding1 from '../../presentation/navbars/navbarlinkslanding1.js';
import prefooterlarge from '../../presentation/prefooters/prefooterlarge.js';
import footersmall from '../../presentation/footers/footersmall.js';

const textProps = {
  color: 'purple-indigo',
  navbar: { ...navbarlinkslanding1 },
  prefooter: { ...prefooterlarge },
  footer: { ...footersmall }
};
export default textProps;
