/* eslint-disable global-require */
const textProps = {
  title: 'Design with us, speed your development...',
  description:
    'We aim high at being focused on building relationships with our clients and community. Using our creative gifts drives this foundation.',
  image: require('../../../assets/img/example-17.svg').default,
  input: {
    placeholder: 'Your email here'
  },
  button: {
    color: 'pink',
    children: 'Join us'
  }
};
export default textProps;
