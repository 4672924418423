import Checkbox from './Checkbox.js';
import ImageUpload from './ImageUpload.js';
import Incrementer from './Incrementer.js';
import IncrementerSocials from './IncrementerSocials.js';
import Progress from './Progress.js';
import Radio from './Radio.js';
import Select1 from './Select1.js';
import Select2 from './Select2.js';
import Select3 from './Select3.js';

const componentsArray = [
  Checkbox,
  ImageUpload,
  Incrementer,
  IncrementerSocials,
  Progress,
  Radio,
  Select1,
  Select2,
  Select3
];
export default componentsArray;
