const textProps = {
  // eslint-disable-next-line global-require
  image: require('../../../assets/img/portofino-1.jpg').default,
  title: 'Latest Boats Project',
  description: 'This is our latest project for the beautiful Portofino Boats...',
  icon: 'fas fa-anchor',
  color: 'orange',
  button: {
    size: 'sm',
    color: 'orange',
    children: 'Find more...'
  },
  alignment: 'left'
};

export default textProps;
