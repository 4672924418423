/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/faces/christian.jpg').default,
  name: 'Charlie Watson',
  color: 'dark',
  lastOnline: 'Online',
  lastMessage: 'Typing...',
  link: {
    href: '#pablo'
  }
};
export default textProps;
