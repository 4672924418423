import HeaderImage from './HeaderImage.js';
import HeaderImageTitleLeft from './HeaderImageTitleLeft.js';
import HeaderBlogPost from './HeaderBlogPost.js';
import HeaderImageTitleButtonCenter from './HeaderImageTitleButtonCenter.js';

const componentsArray = [
  HeaderImage,
  HeaderImageTitleLeft,
  HeaderBlogPost,
  HeaderImageTitleButtonCenter
];
export default componentsArray;
