/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// components

export default function FooterSmall({ copyright, links, type }) {
  let copyrightClass = 'text-center xl:text-left pb-6 pt-2 text-sm';
  const wrapperClasses = {
    dark: 'bg-white',
    light: 'bg-white'
  };
  const copyrightClasses = {
    dark: 'text-white',
    light: 'text-blueGray-500'
  };
  return (
    <div className="absolute bottom-0 w-full rounded-b-2xl">
      <footer className={wrapperClasses[type] + ' bg-white mt-4 rounded-2xl '}>
        <div className="container mx-auto px-4 bg-white">
          <div className="items-center xl:justify-between flex flex-wrap -mx-4 bg-white">
            <div className="px-4 relative w-full xl:w-6/12 sm:w-full ">
              <div className={copyrightClass + ' ' + copyrightClasses[type]}>
                ©
                {copyright && copyright.to ? (
                  <Link {...copyright} className="font-semibold ml-1" />
                ) : (
                  <a {...copyright} className="font-semibold ml-1" />
                )}
                {' ' + String(new Date().getFullYear())}. All rights reserved.
              </div>
            </div>

            <div className="px-4 relative w-full xl:w-6/12 sm:w-full">
              <ul className="justify-center xl:justify-end mx-auto flex flex-wrap list-none pl-0 mb-0">
                {links.map((prop, key) => {
                  return (
                    <li key={key}>
                      {prop && prop.to ? (
                        <Link
                          {...prop}
                          className="text-sm block px-4 bg-transparent no-underline text-blueGray-500 hover:text-blueGray-700 py-4 md:py-6 mx-auto"
                        />
                      ) : (
                        <a
                          {...prop}
                          className="text-sm block px-4 bg-transparent no-underline text-blueGray-500 hover:text-blueGray-700 py-4 md:py-6 mx-auto"
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

FooterSmall.defaultProps = {
  copyright: {},
  links: [],
  type: 'light'
};

FooterSmall.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']),
  // this is a link, so if you pass "to" as prop
  // it will be generated as a Link from react-router-dom
  // otherwise, it will be generated as a simple anchor tag
  copyright: PropTypes.object,
  // same as above, just that this an array of them
  links: PropTypes.arrayOf(PropTypes.object)
};
