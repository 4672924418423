import navbarlinkslanding1 from '../../presentation/navbars/navbarlinkslanding1.js';
import prefooterlargereset from '../prefooters/prefooterlargereset.js';
import footersmallreset from '../footers/footersmallreset.js';

const textProps = {
  // eslint-disable-next-line global-require
  image: require('../../../assets/img/illustrations/register_bg.png').default,
  navbar: { ...navbarlinkslanding1 },
  prefooter: { ...prefooterlargereset },
  footer: { ...footersmallreset }
};
export default textProps;
