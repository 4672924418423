/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/team-2-800x800.jpg').default,
  name: 'Jenna Stones',
  location: 'Los Angeles, California',
  description:
    'An artist of considerable range, Jenna the name taken by Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. An artist of considerable range.',
  showMore: {
    children: 'Show more',
    href: '#pablo'
  },
  stats: [
    {
      title: '22',
      subtitle: 'Friends'
    },
    {
      title: '10',
      subtitle: 'Photos'
    },
    {
      title: '89',
      subtitle: 'Comments'
    }
  ],
  buttons: [
    {
      children: 'Connect',
      color: 'lightBlue',
      size: 'sm'
    },
    {
      children: 'Message',
      color: 'dark',
      size: 'sm'
    }
  ],
  achievements: [
    {
      icon: 'fas fa-briefcase',
      text: 'Solution Manager - Creative Tim Officer'
    },
    {
      icon: 'fas fa-university',
      text: 'University of Computer Science'
    }
  ]
};
export default textProps;
