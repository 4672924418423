/* eslint-disable global-require */
import navbar from '../../presentation/navbars/navbarlinkslanding1.js';
import footer from '../footers/footerauth.js';

const textProps = {
  image: require('../../../assets/img/register_bg_2.png').default,
  navbar: { ...navbar },
  footer: { ...footer }
};
export default textProps;
