const textProps = {
  icon: 'fas fa-atom',
  color: 'lightBlue',
  title: 'Awesome features',
  description: 'The kit comes with three pre-built pages to help you get started faster.',
  list: [
    {
      icon: 'fas fa-check',
      text: 'Beautiful elements'
    },
    {
      icon: 'fas fa-check',
      text: 'Amazing page examples'
    },
    {
      icon: 'fas fa-check',
      text: 'Super friendly support team'
    }
  ]
};

export default textProps;
