import mediaplayerblockquote from '../mediaplayers/mediaplayerblockquote.js';

const textProps = {
  heading2: {
    color: 'lightBlue',
    subtitle: 'Our customers',
    title: 'Some nice testimonials',
    description:
      'You are the one who tells me, that you want to help me. That you want to make me a better life, but you should be in prison, not me.',
    alignment: 'center'
  },
  mediaplayer: { ...mediaplayerblockquote }
};

export default textProps;
