const textProps = {
  badge: {
    color: 'teal',
    children: 'Transformers'
  },
  title: 'Is you car a Transformer?',
  description:
    'This question has been daunting me for ages. What if my new Bugatti that I have woken up in, is actually a Transformer, and what should I do in this case?',
  alignment: 'left'
};

export default textProps;
