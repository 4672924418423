const textProps = {
  text: 'Demo Pages',
  items: [
    { disabled: true, children: 'Group 1' },
    { href: '#pablo', children: 'Demo Page 1' },
    { href: '#pablo', children: 'Demo Page 2' },
    { disabled: true, children: 'Group 2' },
    { href: '#pablo', children: 'Demo Page 3' },
    { href: '#pablo', children: 'Demo Page 4' },
    { disabled: true, children: 'Group 3' },
    { href: '#pablo', children: 'Demo Page 5' },
    { href: '#pablo', children: 'Demo Page 6' },
    { disabled: true, children: 'Group 4' },
    { href: '#pablo', children: 'Demo Page 7' },
    { href: '#pablo', children: 'Demo Page 8' }
  ]
};
export default textProps;
