/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/theme/profile-cover.jpg').default,
  color: 'lightBlue',
  gradient: true,
  title: 'Hello Jesse',
  description:
    "This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks",
  button: {
    color: 'light',
    children: 'Edit profile'
  }
};
export default textProps;
