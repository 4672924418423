/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/illustrations/linth3.svg').default,
  title: 'Reset Password',
  subtitle: 'Enter email address below',
  input: {
    leftIcon: 'fas fa-envelope',
    type: 'email',
    placeholder: 'Email address'
  },
  button: {
    color: 'dark',
    children: 'Reset'
  }
};
export default textProps;
