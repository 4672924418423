/* eslint-disable global-require */
const textProps = {
  defaultOpened: 0,
  size: 'sm',
  items: [
    require('../../../assets/img/sections/sofia-kuniakina.jpg').default,
    require('../../../assets/img/sections/sacha-styles.jpg').default,
    require('../../../assets/img/sections/victor-garcia.jpg').default,
    require('../../../assets/img/sections/doyoun-seo.jpg').default,
    require('../../../assets/img/sections/ayo-ogunseinde.jpg').default
  ]
};
export default textProps;
