/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/castle-1.jpg').default,
  title: 'That’s One Way To Ditch Your Castle',
  description:
    'Finding temporary housing should be as easy as renting an Airbnb. That’s the idea behind Portofino, which raised $65 million to expand its pet sitting businesses. This come as the right move for the investment while the planet is moving on work from home...',
  user: {
    image: require('../../../assets/img/faces/team-4.jpg').default,
    name: 'Tania Hanks'
  },
  icon: 'fas fa-heart',
  text: '2.3K',
  link: {
    href: '#pablo'
  }
};

export default textProps;
