const textProps = {
  title: 'Social traffic',
  color: 'white',
  head: ['Referral', 'Visitors', ''],
  body: [
    [
      {
        bold: 'Facebook'
      },
      '1,480',
      {
        text: '60%',
        progress: { value: 60, color: 'red' }
      }
    ],
    [
      {
        bold: 'Facebook'
      },
      '5,480',
      {
        text: '70%',
        progress: { value: 70, color: 'emerald' }
      }
    ],
    [
      {
        bold: 'Google'
      },
      '4,807',
      {
        text: '80%',
        progress: { value: 80, color: 'indigo' }
      }
    ],
    [
      {
        bold: 'Instagram'
      },
      '3,678',
      {
        text: '75%',
        progress: { value: 75, color: 'lightBlue' }
      }
    ],
    [
      {
        bold: 'Twitter'
      },
      '2,645	',
      {
        text: '30%',
        progress: { value: 30, color: 'amber' }
      }
    ]
  ]
};
export default textProps;
