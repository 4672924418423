/* eslint-disable global-require */
const textProps = {
  image: require('../../../assets/img/pages/tshirt5.jpg').default,
  title: 'Burberry',
  type: 'borderless',
  price: '$2.300',
  badge: {
    color: 'teal',
    children: 'new collection'
  },
  link: {
    href: '#pablo'
  }
};
export default textProps;
