import InfoAreaIconTitleLink from './InfoAreaIconTitleLink.js';
import InfoAreaIconTitleListColor from './InfoAreaIconTitleListColor.js';
import InfoAreaUser from './InfoAreaUser.js';
import InfoAreaWhiteTitle from './InfoAreaWhiteTitle.js';
import InfoAreaIconTitleLinkColor from './InfoAreaIconTitleLinkColor.js';
import InfoAreaIcon from './InfoAreaIcon.js';

const componentsArray = [
  InfoAreaIconTitleLink,
  InfoAreaIconTitleListColor,
  InfoAreaUser,
  InfoAreaWhiteTitle,
  InfoAreaIconTitleLinkColor,
  InfoAreaIcon
];
export default componentsArray;
