const textProps = {
  copyright: {
    children: 'Creative Tim',
    href: 'https://www.creative-tim.com',
    target: '_blank'
  },
  links: [
    {
      children: 'Creative Tim',
      href: 'https://www.creative-tim.com',
      target: '_blank'
    },
    {
      children: 'About us',
      href: 'https://www.creative-tim.com/presentation',
      target: '_blank'
    },
    {
      children: 'Blog',
      href: 'https://www.creative-tim.com/blog',
      target: '_blank'
    }
  ]
};
export default textProps;
