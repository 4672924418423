/* eslint-disable global-require */
const textProps = {
  brand: {
    text: 'Burgin Collection',
    image: require('../../../assets/img/brand/burgin_logo.png').default,
    link: {
      href: '/dashboard/'
    }
  },
  activeColor: 'lightBlue',
  items: [
    {
      divider: true
    },
    {
      title: 'Section 1'
    },
    {
      icon: 'fas fa-tv',
      text: 'Page 1 for Section 1',
      active: true,
      link: {
        href: '#pablo'
      }
    },
    {
      icon: 'fas fa-tools',
      text: 'Page 2 for Section 1',
      link: {
        href: '#pablo'
      }
    },
    {
      icon: 'fas fa-table',
      text: 'Page 3 for Section 1',
      link: {
        href: '#pablo'
      }
    },
    {
      divider: true
    },
    {
      title: 'Section 2'
    },
    {
      icon: 'fas fa-map-marked',
      text: 'Page 1 for Section 2',
      link: {
        href: '#pablo'
      }
    },
    {
      icon: 'fas fa-fingerprint',
      text: 'Page 2 for Section 2',
      link: {
        href: '#pablo'
      }
    },
    {
      icon: 'fas fa-clipboard-list',
      text: 'Page 3 for Section 2',
      link: {
        href: '#pablo'
      }
    },
    {
      divider: true
    },
    {
      title: 'Web Contents'
    },
    {
      icon: 'fas fa-browser',
      text: 'Website Pages',
      active: true,
      link: {
        href: '/websitepages/'
      }
    },
    {
      icon: 'fas fa-book-open',
      text: 'Books',
      link: {
        href: '/books/'
      }
    },
    {
      icon: 'fas fa-archway',
      text: 'Various',
      link: {
        href: '/various/'
      }
    },
    {
      icon: 'fas fa-democrat',
      text: 'Arts',
      link: {
        href: '/arts/'
      }
    },
    {
      divider: true
    },
    {
      title: 'Inquiries'
    },
    {
      icon: 'fas fa-envelope',
      text: 'Messages',
      link: {
        href: '/messages/'
      }
    },
    {
      icon: 'fas fa-shopping-cart',
      text: 'Orders',
      link: {
        href: '/orders/'
      }
    }
  ]
};
export default textProps;
