const addCategoryProps = {
  title: 'Adding Category Information',
  buttonCancel: { children: 'Cancel', size: 'sm', color: 'red' },
  button: { children: 'Save page', size: 'sm', color: 'lightBlue' },
  forms: [
    {
      title: 'Category details',
      inputs: [
        {
          label: 'name',
          width: 6,
          input: {
            placeholder: 'category name',
            type: 'text',
            defaultValue: '',
            name: 'title'
          }
        },
        {
          label: 'German name',
          width: 6,
          input: {
            placeholder: 'German category name',
            type: 'text',
            defaultValue: '',
            name: 'titleGerman'
          }
        },
        {
          label: 'heading',
          width: 6,
          input: {
            placeholder: 'category heading',
            type: 'text',
            defaultValue: '',
            name: 'heading'
          }
        },
        {
          label: 'German heading',
          width: 6,
          input: {
            placeholder: 'Category german heading',
            type: 'text',
            defaultValue: '',
            name: 'headingGerman'
          }
        }
      ]
    }
  ]
};
export default addCategoryProps;
