import React, { Suspense, lazy } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/tailwind.min.css';
import './assets/styles/docs.css';
// Docs Routes - START - you can delete these when you no longer need our docs
import ReactLoader from './components/loader';
import Components from './_docs/layouts/Components.js';
import Documentation from './_docs/layouts/Documentation.js';
import NavbarComponents from './_docs/components/NavbarComponents2.js';
import * as ROUTES from './constants/routes';
import CartContext from './mycomponent/cartContext';
import useShopingCart from './mycomponent/shoppingCart';
import useUser from './mycomponent/useUser';
import useAdmin from './mycomponent/useAdmin';
import useLanguage from './mycomponent/useLanguage';
import useDevise from './mycomponent/useDevise';
// Product Pages - STOP
// Docs Routes -  STOP

// Product Pages - START
// // admin
const Dashboard = lazy(() => import('./views/admin/Dashboard'));
const Original = lazy(() => import('./views/admin/originals'));
const Japanese = lazy(() => import('./views/admin/Japanese'));
const Books = lazy(() => import('./views/admin/Books'));
const Various = lazy(() => import('./views/admin/Various'));
const Maps = lazy(() => import('./views/admin/Maps'));
const Settings2 = lazy(() => import('./views/admin/Settings2'));
const Tables = lazy(() => import('./views/admin/Tables'));
const Messages = lazy(() => import('./views/admin/Messages'));
const Bespokes = lazy(() => import('./views/admin/Bespokes'));
const Orders = lazy(() => import('./views/admin/Orders'));
const AdminLogin = lazy(() => import('./views/auth/LoginAdmin'));
const DynamicCategory = lazy(() => import('./views/admin/DynamicPage'));
// // auth
const Login1 = lazy(() => import('./views/auth/Login1'));
const Login2 = lazy(() => import('./views/auth/Login2'));
const Register1 = lazy(() => import('./views/auth/Register1'));
const Register2 = lazy(() => import('./views/auth/Register2'));
const Reset = lazy(() => import('./views/auth/Reset'));
// // blog
const BlogPost = lazy(() => import('./views/blog/BlogPost'));
const BlogPosts = lazy(() => import('./views/blog/BlogPosts'));
// // e-commerce
const Chat = lazy(() => import('./views/e-commerce/Chat'));
// const Checkout = lazy(() => import('./views/e-commerce/Checkout'));
const ECommerce = lazy(() => import('./views/e-commerce/ECommerce'));
const Invoice = lazy(() => import('./views/e-commerce/Invoice'));
const Pricing = lazy(() => import('./views/e-commerce/Pricing'));
const Product = lazy(() => import('./views/e-commerce/Product'));
const Profile1 = lazy(() => import('./views/e-commerce/Profile1'));
const Settings1 = lazy(() => import('./views/e-commerce/Settings1'));
// // error
const Error404 = lazy(() => import('./views/error/Error404'));
const Error500 = lazy(() => import('./views/error/Error500'));
// // presentation
const Landing1 = lazy(() => import('./views/presentation/Landing1'));
const Landing2 = lazy(() => import('./views/presentation/Landing2'));
const AboutUs = lazy(() => import('./views/presentation/AboutUs'));
const ContactUs = lazy(() => import('./views/presentation/ContactUs'));
const Home = lazy(() => import('./views/presentation/home'));
const DynamicPage = lazy(() => import('./views/presentation/page'));
const About = lazy(() => import('./views/presentation/about'));
const Contact = lazy(() => import('./views/presentation/contact'));
const Bespoke = lazy(() => import('./views/presentation/bespoke'));
const BooksPresentation = lazy(() => import('./views/presentation/books'));
const BooksCategory = lazy(() => import('./views/presentation/bookCategory'));
const OriginalArtCategory = lazy(() => import('./views/presentation/originalCategory'));
const OriginalArt = lazy(() => import('./views/presentation/original'));
const VariousPresentation = lazy(() => import('./views/presentation/various'));
const VariousCategory = lazy(() => import('./views/presentation/variousCategory'));
const JapaneseCategory = lazy(() => import('./views/presentation/japaneseCategory'));
const JapanesePresentation = lazy(() => import('./views/presentation/japanese'));
const ProductDetail = lazy(() => import('./views/presentation/ProductDetail'));
const ProductDetailDynamic = lazy(() => import('./views/presentation/ProductDetailDynamic'));
const Cart = lazy(() => import('./views/presentation/cart'));
const Checkout = lazy(() => import('./views/presentation/checkout'));
const LoginCollection = lazy(() => import('./views/auth/LoginCollection'));
const SignIn = lazy(() => import('./views/auth/SignIn'));
const DashboardPresentation = lazy(() => import('./views/presentation/DashboardPresentation'));
const DynamicCategoryPresentation = lazy(() => import('./views/presentation/newCategory'));

export default function App() {
  const { cart, cartTotal, addToCart, removeFromCart, emptyCart, total } = useShopingCart();
  const { user, setUser } = useUser();
  const { admin, setAdmin } = useAdmin();
  const { language, setLanguage } = useLanguage();
  const { devise, setDevise } = useDevise();
  return (
    <CartContext.Provider
      value={{
        cart,
        cartTotal,
        addToCart,
        removeFromCart,
        emptyCart,
        user,
        setUser,
        admin,
        setAdmin,
        language,
        setLanguage,
        devise,
        setDevise,
        total
      }}
    >
      <HashRouter>
        <Suspense fallback={<ReactLoader />}>
          <Switch>
            {/* Product Pages - START */}
            {/* admin */}
            <Route exact path="/">
              <Redirect to={ROUTES.HOME} />
            </Route>
            <Route
              path={ROUTES.HOME}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-6">
                    <Home className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ABOUT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <About className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CONTACT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Contact className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.BESPOKE}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Bespoke className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.PRESENTATION_BOOKS}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <BooksPresentation className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CATEGORY_BOOKS}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <BooksCategory className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DYNAMIC_PAGE}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <DynamicPage className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.PRESENTATION_ORIGINAL}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <OriginalArt className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CATEGORY_ORIGINAL}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <OriginalArtCategory className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.PRESENTATION_VARIOUS}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <VariousPresentation className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CATEGORY_VARIOUS}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <VariousCategory className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DYNAMIC_CATEGORY}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <DynamicCategoryPresentation className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DYNAMIC_PRODUCT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <ProductDetailDynamic className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.PRESENTATION_JAPANESE}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <JapanesePresentation className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CATEGORY_JAPANESE}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <JapaneseCategory className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ITEM_DETAIL}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <ProductDetail className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CART}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Cart className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CHECKOUT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Checkout className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_PRESENTATION}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <DashboardPresentation className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.SIGNIN}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <SignIn className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.LOGIN}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <LoginCollection className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ADMIN_LOGIN}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <AdminLogin className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Dashboard />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.BOOKS}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Books />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.VARIOUS}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Various />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ADMIN_DYNAMIC}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <DynamicCategory />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ORIGINAL_ARTS}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Original />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ORDERS}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Orders />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.ADMIN_BESPOKE}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Bespokes />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.MESSAGES}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Messages />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.JAPANESE_COLLECTABLES}
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Japanese />
                  </div>
                </>
              )}
            />

            <Route
              path="/"
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Error404 />
                  </div>
                </>
              )}
            />
            <Route
              path="/error-500"
              exact
              render={() => (
                <>
                  <NavbarComponents />
                  <div className="pt-17">
                    <Error500 />
                  </div>
                </>
              )}
            />
          </Switch>
        </Suspense>
      </HashRouter>
    </CartContext.Provider>
  );
}
