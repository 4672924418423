/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { getSubcategories } from '../../services/api';
import * as ROUTES from '../../constants/routes';

export default function Sidebar({ brand, items, activeColor }) {
  const [sidebarShow, setSidebarShow] = React.useState('-translate-x-full');
  const activeColors = {
    red: 'text-red-500 hover:text-red-700',
    orange: 'text-orange-500 hover:text-orange-700',
    amber: 'text-amber-500 hover:text-amber-700',
    emerald: 'text-emerald-500 hover:text-emerald-700',
    teal: 'text-teal-500 hover:text-teal-700',
    lightBlue: 'text-lightBlue-500 hover:text-lightBlue-700',
    indigo: 'text-indigo-500 hover:text-indigo-700',
    purple: 'text-purple-500 hover:text-purple-700',
    pink: 'text-pink-500 hover:text-pink-700'
  };
  const location = useLocation();
  const [items_, setItems_] = useState([]);

  useEffect(() => {
    let mounted = true;

    async function assignCurrentMenu(_items) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < _items.length; i++) {
        if (_items[i].active !== undefined && _items[i].active !== null) {
          // console.log(location.pathname);
          // console.log(items[i].link.to);
          _items[i].active = matchPath(location.pathname, _items[i].link.to);
          if (matchPath(location.pathname, _items[i].link.to)) console.log(_items[i]);
        }
      }
    }

    if (items_.length) {
      assignCurrentMenu(items_);
    }

    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, [items_]);

  useEffect(() => {
    let mounted = true;

    async function popUpMenu() {
      // eslint-disable-next-line no-plusplus
      const res = await getSubcategories();
      const menus = [];
      if (res.subcategories) {
        const { subcategories } = res;
        for (let i = 0; i < subcategories.length; i++) {
          if (
            subcategories[i].subcategoryName.toLowerCase() !== 'book' &&
            subcategories[i].subcategoryName.toLowerCase() !== 'various' &&
            subcategories[i].subcategoryName.toLowerCase() !== 'original' &&
            subcategories[i].subcategoryName.toLowerCase() !== 'japanese'
          ) {
            const subcategoryName = subcategories[i].subcategoryName.toUpperCase();
            const a = {
              icon: 'fas fa-gem',
              text: subcategoryName,
              active: false,
              link: {
                to: `${ROUTES.ADMIN}/${subcategories[i].subcategoryName}`
              }
            };
            menus.push(a);
            console.log('menu :', a);
          }
        }
        if (items) {
          if (items[1]) {
            const temp = [...items];
            let fin = [];

            if (items.length <= 11) {
              const pre_array = [temp[0], temp[1], temp[2], temp[3], temp[4], temp[5]];
              const post_array = [temp[6], temp[7], temp[8], temp[9], temp[10]];
              fin = [...pre_array].concat([...menus]).concat([...post_array]);
            }
            setItems_(fin);
            // console.log('Rightlinks : ', rightLinks);
          }
        }
      }
    }

    if (mounted) {
      popUpMenu();
    }

    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, []);

  return (
    <>
      <nav
        className={`block py-4 px-6 pt-12 top-0 bottom-0 w-64 bg-white shadow-xl left-0 fixed flex-row flex-nowrap md:z-10 z-9999 transition-all duration-300 ease-in-out transform md:translate-x-0 ${sidebarShow}`}
      >
        <button
          type="button"
          className="md:hidden flex items-center justify-center cursor-pointer text-blueGray-700 w-6 h-10 border-l-0 border-r border-t border-b border-solid border-blueGray-100 text-xl leading-none bg-white rounded-r border border-transparent absolute top-1/2 -right-24-px focus:outline-none z-9998"
          onClick={() => {
            if (sidebarShow === '') {
              setSidebarShow('-translate-x-full');
            } else {
              setSidebarShow('');
            }
          }}
        >
          <i className="fas fa-ellipsis-v" />
        </button>
        {/* Collapse */ console.log(items)}
        <div className="flex-col min-h-full px-0 flex flex-wrap items-center justify-between w-full mx-auto overflow-y-auto overflow-x-hidden">
          <div className="flex bg-white flex-col opacity-100 relative mt-4 overflow-y-auto overflow-x-hidden h-auto z-40 items-center flex-1 rounded w-full">
            {/* Brand */}
            <div className="md:flex-col md:min-w-full flex flex-col list-none">
              {items_.map((prop, key) => {
                if (prop.divider) {
                  return <hr key={key} className="my-4 md:min-w-full" />;
                }
                if (prop.title) {
                  return (
                    <h6
                      key={key}
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                    >
                      {prop.title}
                    </h6>
                  );
                }
                if (prop.link && prop.link.to) {
                  return (
                    <Link
                      {...prop.link}
                      key={key}
                      className={classnames('text-xs uppercase py-3 font-bold block', {
                        [activeColors[activeColor]]: matchPath(location.pathname, prop.link.to),
                        'text-blueGray-800 hover:text-blueGray-500':
                          !matchPath(location.pathname, prop.link.to) || prop.active === undefined
                      })}
                    >
                      <i
                        className={classnames(prop.icon, 'mr-2 text-sm', {
                          'opacity-75': matchPath(location.pathname, prop.link.to),
                          'text-blueGray-400':
                            !matchPath(location.pathname, prop.link.to) || prop.active === undefined
                        })}
                      />
                      {prop.text}
                    </Link>
                  );
                }
                return (
                  <a
                    {...prop.link}
                    key={key}
                    className={classnames('text-xs uppercase py-3 font-bold block', {
                      [activeColors[activeColor]]: prop.active,
                      'text-blueGray-800 hover:text-blueGray-500':
                        !prop.active || prop.active === undefined
                    })}
                  >
                    <i
                      className={classnames(prop.icon, 'mr-2 text-sm', {
                        'opacity-75': prop.active,
                        'text-blueGray-400': !prop.active || prop.active === undefined
                      })}
                    />
                    {prop.text}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

Sidebar.defaultProps = {
  items: [],
  activeColor: 'pink'
};

Sidebar.propTypes = {
  // this only applies for those items that have active set to true
  activeColor: PropTypes.oneOf([
    'red',
    'orange',
    'amber',
    'emerald',
    'teal',
    'lightBlue',
    'indigo',
    'purple',
    'pink'
  ]),
  brand: PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.string,
    // props to pass to the wrapper link of the text and image
    // if you pass a prop named to, it will be generated as
    // Link from react-router-dom
    link: PropTypes.object
  }),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // this will generate a divider hr tag
      PropTypes.shape({
        divider: PropTypes.bool
      }),
      // this will generate a text with the title string
      PropTypes.shape({
        title: PropTypes.string
      }),
      // this will generate a Link/Anchor with bellow options
      PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
        // if set to true, the link will change color to the active one
        active: PropTypes.bool,
        // props to pass to the wrapper link of the text and icon
        // if you pass a prop named to, it will be generated as
        // Link from react-router-dom
        link: PropTypes.object
      })
    ])
  )
};
